import {
  CREATABLE_LIST_REQUEST,
  CREATABLE_LIST_SUCCESS,
  CREATABLE_LIST_FAIL,
  CREATABLE_LIST_UPDATE,
  CREATE_BATCHES_REQUEST,
  CREATE_BATCHES_SUCCESS,
  CREATE_BATCHES_FAIL,
  CREATE_BATCHES_RESET,
  BATCHES_LIST_REQUEST,
  BATCHES_LIST_SUCCESS,
  BATCHES_LIST_FAIL,
  BATCHES_LIST_UPDATE,
  INWARDING_LIST_REQUEST,
  INWARDING_LIST_SUCCESS,
  INWARDING_LIST_FAIL,
  MARK_RECD_REQUEST,
  MARK_RECD_SUCCESS,
  MARK_RECD_FAIL,
  MARK_RECD_RESET,
  NPI_CREATABLE_LIST_REQUEST,
  NPI_CREATABLE_LIST_SUCCESS,
  NPI_CREATABLE_LIST_FAIL,
  NPI_CREATABLE_LIST_UPDATE,
  NPI_BATCHES_LIST_REQUEST,
  NPI_BATCHES_LIST_SUCCESS,
  NPI_BATCHES_LIST_FAIL,
  NPI_INWARDING_LIST_REQUEST,
  NPI_INWARDING_LIST_SUCCESS,
  NPI_INWARDING_LIST_FAIL,
  NPI_BATCH_APPROVE_REQUEST,
  NPI_BATCH_APPROVE_SUCCESS,
  NPI_BATCH_APPROVE_FAIL,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_SUCCESS,
  BATCH_DELETE_FAIL,
  BATCH_EDIT_REQUEST,
  BATCH_EDIT_SUCCESS,
  BATCH_EDIT_FAIL,
} from "../Constants/AdminConstants";

export const listCreatableBatchesReducer = (
  state = { creatableList: {} },
  action
) => {
  switch (action.type) {
    case CREATABLE_LIST_REQUEST:
      return { loading: true };
    case CREATABLE_LIST_SUCCESS:
      return { loading: false, creatableList: action.payload };
    case CREATABLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CREATABLE_LIST_UPDATE:
      return { creatableList: action.payload };
    default:
      return state;
  }
};

export const NPIlistCreatableBatchesReducer = (
  state = { NPIcreatableList: {} },
  action
) => {
  switch (action.type) {
    case NPI_CREATABLE_LIST_REQUEST:
      return { loading: true };
    case NPI_CREATABLE_LIST_SUCCESS:
      return { loading: false, NPIcreatableList: action.payload };
    case NPI_CREATABLE_LIST_FAIL:
      return { loading: false, error: action.payload };
    case NPI_CREATABLE_LIST_UPDATE:
      return { creatableList: action.payload };
    default:
      return state;
  }
};

export const batchCreateReducer = (state = { batchesCreated: {} }, action) => {
  switch (action.type) {
    case CREATE_BATCHES_REQUEST:
      return { loading: true };
    case CREATE_BATCHES_SUCCESS:
      return { loading: false, success: true, batchesCreated: action.payload };
    case CREATE_BATCHES_FAIL:
      return { loading: false, error: action.payload };
    case CREATE_BATCHES_RESET:
      return {};
    default:
      return state;
  }
};

export const listBatchesReducer = (state = { BatchList: {} }, action) => {
  switch (action.type) {
    case BATCHES_LIST_REQUEST:
      return { loading: true };
    case BATCHES_LIST_SUCCESS:
      return { loading: false, BatchList: action.payload };
    case BATCHES_LIST_FAIL:
      return { loading: false, error: action.payload };
    case BATCHES_LIST_UPDATE:
      return { loading: false, BatchList: action.payload };
    default:
      return state;
  }
};

export const NPIlistBatchesReducer = (state = { NPIBatchList: {} }, action) => {
  switch (action.type) {
    case NPI_BATCHES_LIST_REQUEST:
      return { loading: true };
    case NPI_BATCHES_LIST_SUCCESS:
      return { loading: false, NPIBatchList: action.payload };
    case NPI_BATCHES_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const listInwardingReducer = (state = { Inwarding: {} }, action) => {
  switch (action.type) {
    case INWARDING_LIST_REQUEST:
      return { loading: true };
    case INWARDING_LIST_SUCCESS:
      return { loading: false, Inwarding: action.payload };
    case INWARDING_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const NPIlistInwardingReducer = (
  state = { NPIInwarding: {} },
  action
) => {
  switch (action.type) {
    case NPI_INWARDING_LIST_REQUEST:
      return { loading: true };
    case NPI_INWARDING_LIST_SUCCESS:
      return { loading: false, NPIInwarding: action.payload };
    case NPI_INWARDING_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const markReceivedReducer = (state = { recd: {} }, action) => {
  switch (action.type) {
    case MARK_RECD_REQUEST:
      return { loading: true };
    case MARK_RECD_SUCCESS:
      return { loading: false, success: true, recd: action.payload };
    case MARK_RECD_FAIL:
      return { loading: false, error: action.payload };
    case MARK_RECD_RESET:
      return {};
    default:
      return state;
  }
};

export const deleteBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_DELETE_REQUEST:
      return { loading: true };
    case BATCH_DELETE_SUCCESS:
      return { loading: false, success: true };
    case BATCH_DELETE_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const editBatchReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_EDIT_REQUEST:
      return { loading: true };
    case BATCH_EDIT_SUCCESS:
      return { loading: false, success: true };
    case BATCH_EDIT_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};

export const NPIBatchApproveReducer = (state = {}, action) => {
  switch (action.type) {
    case NPI_BATCH_APPROVE_REQUEST:
      return { loading: true };
    case NPI_BATCH_APPROVE_SUCCESS:
      return { loading: false, success: true };
    case NPI_BATCH_APPROVE_FAIL:
      return { loading: false, success: false, error: action.payload };
    default:
      return state;
  }
};
