import MobileFooter from "../Components/MobileFooter";

function Privacyscreen() {
  return (
    <>
      <h1>Privacy Policy</h1>

      <p>Last Updated on: 28th October, 2021</p>

      <p>
        Jaxy Technologies Pvt. Ltd. recognises the importance of maintaining
        your privacy. We value your privacy and appreciate your trust in us.
        This Privacy Policy sets out how Jaxy Technologies Pvt. Ltd. uses and
        protects any information that you give Jaxy Technologies Pvt. Ltd. when
        you use this https://www.homble.in/ or the Jaxy Technologies Pvt. Ltd.
        mobile application or any other digital medium and other offline sources
        of our Company. Jaxy Technologies Pvt. Ltd. is committed to ensure that
        your privacy is protected. Should we ask you to provide certain
        information by which you can be identified when using this website, then
        you can be assured that it will only be used in accordance with this
        Privacy Policy as it describes how we treat user information we collect
        from you, the policies and procedures on the collection, use, disclosure
        and protection of your information when you use our Jaxy Technologies
        Pvt. Ltd. Platform.
      </p>

      <p>
        This Privacy Policy applies to current and former visitors to our Jaxy
        Technologies Pvt. Ltd. Platform and to our online customers. By visiting
        and/or using our website, you agree to this Privacy Policy. Jaxy
        Technologies Pvt. Ltd. may change this policy from time to time by
        updating this page. You should check this page from time to time to
        ensure that you are happy with any changes.
      </p>

      <p>
        https://www.homble.in/ is a property of Jaxy Technologies Pvt. Ltd.,
        Private Limited, established under laws of India, having its registered
        office at Private Limited, Karnataka - 560102.
      </p>

      <h5>Definitions</h5>

      <p>
        The terms "Homble", "Company", "we", "us" and "our" refer to the Jaxy
        Technologies Pvt. Ltd..
      </p>
      <p>
        The term "Jaxy Technologies Pvt. Ltd. Platform" refers to
        https://www.homble.in/ or the Jaxy Technologies Pvt. Ltd. mobile
        application or any other digital medium and other offline sources.
      </p>
      <p>
        The terms "you" and "your" refer to the user of the
        https://www.homble.in/.
      </p>
      <p>
        The term "Group" means, with respect to any person, any entity that is
        controlled by such person, or any entity that controls such person, or
        any entity that is under common control with such person, whether
        directly or indirectly, or, in the case of a natural person, any
        Relative (as such term is defined in the Companies Act, 1956 and
        Companies Act, 2013 to the extent applicable) of such person.
      </p>
      <p>
        The term "Services" refers to any services offered by Jaxy Technologies
        Pvt. Ltd. whether on the Swiggy Platform or otherwise.
      </p>
      <p>The term "Policy" refers to this Privacy Policy.</p>
      <p>
        Please read this Policy before using the Jaxy Technologies Pvt. Ltd.
        Platform or submitting any personal information to Jaxy Technologies
        Pvt. Ltd.. This Policy is a part of and incorporated within, and is to
        be read along with, the Terms of Use https://www.homble.in/tnc.
      </p>

      <h5>Information we collect from you</h5>

      <p>
        <strong>Contact Information</strong>
      </p>
      <p>
        We might collect your <strong>Name, Email, Phone, Address.</strong>
      </p>

      <p>
        <strong>Payment and Billing Information</strong>
      </p>
      <p>
        We might collect your billing name, billing address and payment method
        when you buy a product/service. We NEVER collect your credit card number
        or credit card expiry date or other details pertaining to your credit
        card on our website. Credit card information will be obtained and
        processed by our online payment partner Cashfree,Razorpay.
      </p>

      <p>
        <strong>Information you post</strong>
      </p>
      <p>
        We collect information you post in a public space on our Jaxy
        Technologies Pvt. Ltd. Platform or on a third-party social media site
        belonging to Jaxy Technologies Pvt. Ltd..
      </p>

      <p>
        <strong>Usage and Preference Information</strong>
      </p>
      <p>
        We collect information as to how you interact with our Services,
        preferences expressed and settings chosen. Jaxy Technologies Pvt. Ltd.
        Platform includes the Jaxy Technologies Pvt. Ltd. advertising services
        ("Ad Services"), which may collect user activity and browsing history
        within the Jaxy Technologies Pvt. Ltd. Platform and across third-party
        sites and online services, including those sites and services that
        include our ad pixels ("Pixels"), widgets, plug-ins, buttons, or related
        services or through the use of cookies. Our Ad Services collect browsing
        information including, without limitation, your Internet protocol (IP)
        address and location, your login information, browser type and version,
        date and time stamp, user agent, Jaxy Technologies Pvt. Ltd. cookie ID
        (if applicable), time zone setting, browser plug-in types and versions,
        operating system and platform, and other information about user
        activities on the Jaxy Technologies Pvt. Ltd. Platform, as well as on
        third party sites and services that have embedded our Pixels, widgets,
        plug-ins, buttons, or related services
      </p>

      <p>
        <strong>Transaction Information</strong>
      </p>
      <p>
        We collect transaction details related to your use of our Services, and
        information about your activity on the Services, including the full
        Uniform Resource Locators (URL), the type of Services you requested or
        provided, comments, domain names, search results selected, number of
        clicks, information and pages viewed and searched for, the order of
        those pages, length of your visit to our Services, the date and time you
        used the Services, amount charged, details regarding application of
        promotional code, methods used to browse away from the page and any
        phone number used to call our customer service number and other related
        transaction details.
      </p>

      <p>
        <strong>Stored information and Files</strong>
      </p>
      <p>
        Jaxy Technologies Pvt. Ltd. mobile application (Jaxy Technologies Pvt.
        Ltd. app) may also access metadata and other information associated with
        other files stored on your mobile device. This may include, for example,
        photographs, audio and video clips, personal contacts and address book
        information. If you permit the Jaxy Technologies Pvt. Ltd. app to access
        the address book on your device, we may collect names and contact
        information from your address book to facilitate social interactions
        through our services and for other purposes described in this Policy or
        at the time of consent or collection. If you permit the Jaxy
        Technologies Pvt. Ltd. app to access the calendar on your device, we
        collect calendar information such as event title and description, your
        response (Yes, No, Maybe), date and time, location and number of
        attendees. If you are a partner company, we will, additionally, record
        your calls with us made from the device used to provide Services,
        related call details, SMS details location and address details.
      </p>

      <p>
        <strong>Demographic information</strong>
      </p>
      <p>
        We may collect demographic information about you, events you like,
        events you intend to participate in, tickets you buy, or any other
        information provided by you during the use of Jaxy Technologies Pvt.
        Ltd. Platform. We might collect this as a part of a survey also.
      </p>

      <p>
        <strong>Other information</strong>
      </p>
      <p>
        If you use Jaxy Technologies Pvt. Ltd. Platform, we may collect
        information about your IP address and the browser you're using. We might
        look at what site you came from, duration of time spent on our website,
        pages accessed or what site you visit when you leave us. We might also
        collect the type of mobile device you are using, or the version of the
        operating system your computer or device is running.
      </p>

      <h5>Ways of collecting information</h5>

      <p>
        <strong>We collect information directly from you</strong>
      </p>
      <ul>
        <li>
          We collect information directly from you when you register on Jaxy
          Technologies Pvt. Ltd. Platform. We also collect information if you
          post a comment on Jaxy Technologies Pvt. Ltd. Platform or ask us a
          question through phone or email or any other source of communication.
        </li>
      </ul>

      <p>
        <strong>We collect information from you passively</strong>
      </p>
      <ul>
        <li>
          We use tracking tools like Google Analytics for collecting information
          about your usage of our website.
        </li>
      </ul>

      <p>
        <strong>We get information about you from third parties</strong>
      </p>
      <ul>
        <li>
          We may receive information about you from third parties, such as other
          users, partners (including ad partners, analytics providers, search
          information providers), or our affiliated companies or if you use any
          of the other websites/apps we operate or the other Services we
          provide. Users of our Ad Services and other third-parties may share
          information with us such as the cookie ID, device ID, or demographic
          or interest data, and information about content viewed or actions
          taken on a third-party website, online services or apps. For example,
          users of our Ad Services may also be able to share customer list
          information (e.g., email or phone number) with us to create customized
          audience segments for their ad campaigns
        </li>

        <li>
          When you sign in to Jaxy Technologies Pvt. Ltd. Platform with your SNS
          account, or otherwise connect to your SNS account with the Services,
          you consent to our collection, storage, and use, in accordance with
          this Policy, of the information that you make available to us through
          the social media interface. This could include, without limitation,
          any information that you have made public through your social media
          account, information that the social media service shares with us, or
          information that is disclosed during the sign-in process. Please see
          your social media providerâ€™s privacy policy and help center for
          more information about how they share information when you choose to
          connect your account.
        </li>

        <li>
          If you are partner company, we may, additionally, receive feedback and
          rating from other users.
        </li>
      </ul>

      <h5>Use of your personal information</h5>

      <p>
        <strong>We use information to contact you</strong>
      </p>
      <ul>
        <li>
          We might use the information you provide to contact you for
          confirmation of a purchase or delivery on Jaxy Technologies Pvt. Ltd.
          Platform or for other promotional purposes.
        </li>
      </ul>

      <p>
        <strong>We use information to abide by contracts</strong>
      </p>
      <ul>
        <li>
          We might use the information you provide to carry out our obligations
          arising from any contracts entered into between you and us and to
          provide you with the relevant information and services.
        </li>
      </ul>

      <p>
        <strong>
          We use informtaion to respond to your requests or questions
        </strong>
      </p>
      <ul>
        <li>
          We might use your information to confirm your registration for
          rendering services we offer.
        </li>
      </ul>

      <p>
        <strong>We use information to improve our products and services</strong>
      </p>
      <ul>
        <li>
          We might use your information to customize your experience with us.
          This could include displaying content based upon your preferences. We
          might also use your information to understand our users (what they do
          on our Services, what features they like, how they use them, etc.),
          improve the content and features of our Services (such as by
          personalizing content to your interests), process and complete your
          transactions, make special offers, provide customer support, process
          and respond to your queries and to generate and review reports and
          data about, and to conduct research on, our user base and Service
          usage patterns. If you are a partner company, we may use the
          information to track the progress of your service to our customers.
        </li>
      </ul>

      <p>
        <strong>
          We use information to look at site trends and customer interests
        </strong>
      </p>
      <ul>
        <li>
          We may use your information to make our website and products better.
          We may combine information we get from you with information about you
          we get from third parties. We may use your information to provide you
          with information about services we consider similar to those that you
          are already using, or have enquired about, or may interest you. If you
          are a registered user, we will contact you by electronic means (e-mail
          or SMS or telephone) with information about these services.
        </li>
      </ul>

      <p>
        <strong>We use information for security purposes</strong>
      </p>
      <ul>
        <li>
          We may use information to protect our company, our customers, or our
          Jaxy Technologies Pvt. Ltd. Platform.
        </li>
      </ul>

      <p>
        <strong>We use information for marketing purposes</strong>
      </p>
      <ul>
        <li>
          We might send you information about special promotions or offers. We
          might also tell you about new features or products or enroll you in
          our newsletter. These might be our own offers or products, or
          third-party offers or products we think you might find interesting. We
          may use the information to measure or understand the effectiveness of
          advertising we serve to you and others, and to deliver relevant
          advertising to you.
        </li>
      </ul>

      <p>
        <strong>
          We use information to send you transactional communications
        </strong>
      </p>
      <ul>
        <li>
          We might send you emails or SMS about your account or purchase of our
          product.
        </li>
      </ul>

      <p>
        <strong>We use information as otherwise permitted by law</strong>
      </p>
      <ul>
        <li>
          We may combine the information that we receive from third parties with
          the information you give to us and information we collect about you
          for the purposes set out above. Further, we may anonymize and/or
          de-identify information collected from you through the Services or via
          other means, including via the use of third-party web analytic tools.
          As a result, our use and disclosure of aggregated and/or de-identified
          information is not restricted by this Policy, and it may be used and
          disclosed to others without limitation.
        </li>
        <li>
          We analyse the log files of our Jaxy Technologies Pvt. Ltd. Platform
          that may contain Internet Protocol (IP) addresses, browser type and
          language, Internet service provider (ISP), referring, app crashes,
          page viewed and exit websites and applications, operating system,
          date/time stamp, and clickstream data. This helps us to administer the
          website, to learn about user behavior on the site, to improve our
          product and services, and to gather demographic information about our
          user base as a whole.
        </li>
      </ul>

      <h5>
        Sharing/Disclosure/Distribution of your information with third parties
      </h5>

      <p>
        <strong>
          We will share information with third parties who perform services on
          our behalf
        </strong>
      </p>
      <ul>
        <li>
          We may share your information with our vendors, consultants, marketing
          partners, research firms and other service providers or business
          partners, such as payment processing companies, to support our
          business. For example, your information may be shared with outside
          vendors to send you emails and messages or push notifications to your
          devices in relation to our Services, to help us analyze and improve
          the use of our Services, to process and collect payments
        </li>
        <li>
          We may also use vendors for other projects, such as conducting surveys
          or organizing sweepstakes for us. Some vendors may be located outside
          of India.
        </li>
      </ul>

      <p>
        <strong>
          We will share information with Advertisers and advertising networks
        </strong>
      </p>
      <ul>
        <li>
          We may work with third parties such as network advertisers to serve
          advertisements on the Jaxy Technologies Pvt. Ltd. Platform and on
          third-party websites or other media (e.g., social networking
          platforms). These third parties may use cookies, JavaScript, web
          beacons (including clear GIFs), Flash LSOs and other tracking
          technologies to measure the effectiveness of their ads and to
          personalize advertising content to you
        </li>
        <li>
          While you cannot opt out of advertising on the Jaxy Technologies Pvt.
          Ltd. Platform, you may opt out of much interest-based advertising on
          third party sites and through third party ad networks (including
          DoubleClick Ad Exchange, Facebook Audience Network and Google
          AdSense). Opting out means that you will no longer receive
          personalized ads by third parties ad networks from which you have
          opted out, which is based on your browsing information across multiple
          sites and online services. If you delete cookies or change devices,
          your â€˜opt outâ€™ may no longer be effective to fulfill the purpose
          for which you provide it
        </li>
      </ul>

      <p>
        <strong>We will share information with our business partners</strong>
      </p>
      <ul>
        <li>
          This includes a third party who provide us various services. Our
          partners use the information we give them as described in their
          privacy policies.
        </li>
        <li>
          While you place a request to order through the Jaxy Technologies Pvt.
          Ltd. Platform, your information is provided to us and to the vendors
          with whom you may choose to order. In order to facilitate your online
          order processing, we provide your information to that vendors in a
          similar manner as if you had made a goods/products/food order directly
          with the vendors. If you provide a mobile phone number, Jaxy
          Technologies Pvt. Ltd. may send you text messages regarding the orders
          delivery status.
        </li>
      </ul>

      <p>
        <strong>We will share information with other users</strong>
      </p>
      <ul>
        <li>
          If you are a delivery partner or supplier, we may share your name,
          phone number and/or profile picture (if applicable), tracking details
          with other users to provide them the Services
        </li>
      </ul>

      <p>
        <strong>
          We may share information if we think we have to in order to comply
          with the law or to protect ourselves
        </strong>
      </p>
      <p>
        We may also share it if a government agency or investigatory body or
        other companies assisting us, requests when we are:
      </p>
      <ul>
        <li>
          Obligated under the applicable laws or in good faith to respond to
          court orders and processes; or
        </li>
        <li>
          Detecting and preventing against actual or potential occurrence of
          identity theft, fraud, abuse of Services and other illegal acts;
        </li>
        <li>
          Responding to claims that an advertisement, posting or other content
          violates the intellectual property rights of a third party;
        </li>
        <li>
          Under a duty to disclose or share your personal data in order to
          enforce our Terms of Use and other agreements, policies or to protect
          the rights, property, or safety of the Company, our customers, or
          others, or in the event of a claim or dispute relating to your use of
          our Services. This includes exchanging information with other
          companies and organisations for the purposes of fraud detection and
          credit risk reduction.
        </li>
      </ul>

      <p>
        <strong>
          We may share information for Internal Use or may share it with any
          successor to all or part of our business:{" "}
        </strong>
      </p>
      <ul>
        <li>
          For example, if part of our business is sold we may give our customer
          list as part of that transaction. We may also share your information
          with any present or future member of our "Group" (as defined above) or
          affiliates for our internal business purposes
        </li>
      </ul>

      <p>
        <strong>
          We may share your information for reasons not described in this policy
        </strong>
      </p>
      <ul>
        <li>
          We may share your information other than as described in this Policy
          if we notify you and you consent to the sharing. We will tell you
          before we do this
        </li>
      </ul>

      <h5>Your Consent</h5>
      <p>
        By using the Jaxy Technologies Pvt. Ltd. Platform and the Services
        provided therein, you agree and consent to the collection, transfer,
        use, storage, disclosure and sharing of your information as described
        and collected by us in accordance with this Policy. If you do not agree
        with the Policy, please do not use or access the Jaxy Technologies Pvt.
        Ltd. Platform
      </p>

      <h5>Cookies</h5>
      <p>
        Our Jaxy Technologies Pvt. Ltd. Platform and third parties with whom we
        partner, may use cookies, pixel tags, web beacons, mobile device IDs,
        "flash cookies" and similar files or technologies to collect and store
        information with respect to your use of the Services and third-party
        websites.
      </p>
      <p>
        Cookies are small files that are stored on your browser or device by
        websites, apps, online media and advertisements. We use cookies and
        similar technologies for purposes such as:
      </p>
      <ul>
        <li>Authenticating users;</li>
        <li>Remembering user preferences and settings;</li>
        <li>Determining the popularity of content;</li>
        <li>
          Delivering and measuring the effectiveness of advertising campaigns;
        </li>
        <li>
          Analysing site traffic and trends, and generally understanding the
          online behaviours and interests of people who interact with our
          services
        </li>
      </ul>
      <p>
        A pixel tag (also called a web beacon or clear GIF) is a tiny graphic
        with a unique identifier, embedded invisibly on a webpage (or an online
        ad or email), and is used to count or track things like activity on a
        webpage or ad impressions or clicks, as well as to access cookies stored
        on usersâ€™ computers. We use pixel tags to measure the popularity of
        our various pages, features and services. We also may include web
        beacons in e-mail messages or newsletters to determine whether the
        message has been opened and for other analytics.
      </p>
      <p>
        To modify your cookie settings, please visit your browserâ€™s settings.
        By using our Services with your browser settings to accept cookies, you
        are consenting to our use of cookies in the manner described in this
        section.
      </p>
      <p>
        We may also allow third parties to provide audience measurement and
        analytics services for us, to serve advertisements on our behalf across
        the Internet, and to track and report on the performance of those
        advertisements. These entities may use cookies, web beacons, SDKs and
        other technologies to identify your device when you visit the Jaxy
        Technologies Pvt. Ltd. Platform and use our Services, as well as when
        you visit other online sites and services.
      </p>
      <p>
        Please see our Cookie Policy here for more information regarding the use
        of cookies and other technologies described in this section, including
        regarding your choices relating to such technologies.
      </p>

      <h5>Data Security precautions</h5>
      <p>
        We have in place appropriate technical and security measures to secure
        the information collected by us. We use vault and tokenization services
        from third party service providers to protect the sensitive personal
        information provided by you. The third-party service providers with
        respect to our vault and tokenization services and our payment gateway
        and payment processing are compliant with the payment card industry
        standard (generally referred to as PCI compliant service providers). You
        are advised not to send your full credit/debit card details through
        unencrypted electronic platforms. Where we have given you (or where you
        have chosen) a username and password which enables you to access certain
        parts of the Jaxy Technologies Pvt. Ltd. Platform, you are responsible
        for keeping these details confidential. We ask you not to share your
        password with anyone.
      </p>
      <p>
        Please be aware that the transmission of information via the internet is
        not completely secure. Although we will do our best to protect your
        personal data, we cannot guarantee the security of your data transmitted
        through the Jaxy Technologies Pvt. Ltd. Platform. Once we have received
        your information, we will use strict physical, electronic, and
        procedural safeguards to try to prevent unauthorised access.
      </p>

      <h5>Email OPT-OUT</h5>
      <p>
        You can opt out of receiving our marketing emails. To stop receiving our
        promotional emails, please email (info@homble.in). It may take about
        __5__ days to process your request. Even if you opt out of getting
        marketing messages, we will still be sending you transactional messages
        through email and SMS about your purchases.
      </p>

      <h5>Third Party Sites</h5>
      <p>
        The Jaxy Technologies Pvt. Ltd. Platform may contain links to other
        websites. If you click on one of the links to third party websites, you
        may be taken to websites we do not control. Any personal information
        about you collected whilst visiting such websites is not governed by
        this Policy. Jaxy Technologies Pvt. Ltd. shall not be responsible for
        and has no control over the practices and content of any website
        accessed using the links contained on the Jaxy Technologies Pvt. Ltd.
        Platform. Read the privacy policy of other websites carefully as this
        policy does not apply to the privacy practices of those websites. We are
        not responsible for these third party sites.
      </p>
      <p>
        This Policy shall also not apply to any information you may disclose to
        any of our service providers/service personnel which we do not require
        you to disclose to us or any of our service providers under this Policy.
      </p>

      <h5>Grievance Officer</h5>
      <p>
        In accordance with Information Technology Act 2000 and rules made and
        applicable there under, the name and contact details of the Grievance
        Officer are provided below:
      </p>
      <ul>
        <li>Name : Supreeta Jha</li>
        <li>
          Address: No. 148, BBMP No. 170/148, 5th Main Road, Sector – 6, HSR
          Layout, Bangalore - 560102
        </li>
        <li>Phone Number: +91-9632-55-6790</li>
        <li>Email Address: info@homble.in</li>
      </ul>

      <h5>Jurisdiction</h5>
      <p>
        If you choose to visit the website, your visit and any dispute over
        privacy is subject to this Policy and the website's terms of use. In
        addition to the foregoing, any disputes arising under this Policy shall
        be governed by the laws of India.
      </p>
      <p>
        Further, please note that the Jaxy Technologies Pvt. Ltd. Platform
        stores your data with the cloud platform of provided by AWS, which may
        store this data on their servers located. has security measures in place
        to protect the loss, misuse and alteration of the information, details
        of which are available at https://aws.amazon.com/. The privacy policy
        adopted by are detailed in https://aws.amazon.com/privacy/.
      </p>
      <p>
        In the event you have questions or concerns about the security measures
        adopted by AWS, you can contact their data protection/ privacy team/
        legal team or designated grievance officer at these organisations, whose
        contact details are available in its privacy policy, or can also write
        to our Grievance Officer at the address provided above.
      </p>
      <p>
        If you have any queries relating to the processing or usage of
        information provided by you in connection with this Policy or other
        privacy concerns, you can email us at info@homble.in
      </p>
      <MobileFooter />
    </>
  );
}

export default Privacyscreen;
