import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";

export const initAmplitude = (user = "") => {
  amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, user, {
    defaultTracking: {
      attribution: true,
      pageViews: true,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
};

const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: 0.5,
});
amplitude.add(sessionReplayTracking);

export const trackEvent = (eventName, eventProperties) => {
  amplitude.track(eventName, eventProperties);
};

export default amplitude;
