export const categoryRowCount = (categoryList) => {
  const number = categoryList.filter((cat) => cat.productCount > 0).length;
  return;
};

export const getSortedCollectionItems = (collectionItems) => {
  if (!collectionItems) {
    return [];
  }
  return collectionItems.sort((a, b) => {
    return (
      (b?.quantityAvailable > 0 ? 1 : 0) - (a?.quantityAvailable > 0 ? 1 : 0)
    );
  });
};
