import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { HashRouter } from "react-router-dom";
import { useJsApiLoader } from "@react-google-maps/api";
import Container from "react-bootstrap/Container";
import AppRouter from "./AppRouter";
import FlashMessage from "./Components/FlashMessage";
import Header from "./Components/Header";
import ScrollToTop from "./Components/ScrollToTop";
import Loader from "./Components/Loader";
import { getCartList } from "./Actions/CartActions";
import { initAmplitude } from "./Config/amplitude";

const libs = ["geometry", "places"];

function App() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDWVvVXDejQDy-KsCCsCaDwFYMI80iK6dg",
    libraries: libs,
  });
  const [showFlashMessage, setShowFlashMessage] = useState(false);
  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;
  const flashMessage = useSelector((state) => state.flashMessage);
  const { message } = flashMessage;

  const dispatch = useDispatch();

  useEffect(() => {
    initAmplitude(login?.mobile);
  }, []);

  useEffect(() => {
    if (message && Object.keys(message).length !== 0) {
      setShowFlashMessage(true);
    }
  }, [message]);

  useEffect(() => {
    let cartId = localStorage.getItem("cartId")
      ? JSON.parse(localStorage.getItem("cartId"))
      : null;
    if (cartId) {
      dispatch(getCartList(cartId));
    }
  }, []);

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <HashRouter>
      <ScrollToTop />
      <Header />
      <main>
        <Container className="mainContainer" style={{ paddingBottom: 100 }}>
          <FlashMessage
            show={showFlashMessage}
            setShow={setShowFlashMessage}
            delay={message.delay ? message.delay : 3000}
            background={message.background ? message.background : "primary"}
            message={message.string}
            position={message.position ? message.position : "top-center"}
          />
          <AppRouter />
        </Container>
      </main>
    </HashRouter>
  );
}

export default App;
