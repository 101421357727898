import MobileFooter from "../Components/MobileFooter";

function Termsscreen() {
  return (
    <div>
      <h1>TERMS OF SERVICE AGREEMENT</h1>

      <p>Last Revision: 29th October 2021</p>
      <p>
        Welcome to https://www.homble.in/. This website is owned and operated by
        Jaxy Technologies Pvt. Ltd. and No. 148, BBMP No. 170/148,,5th Main
        Road, Sector -6, HSR Layout,Bangalore,Karnataka,560102. By visiting our
        website and accessing the information, resources, services, products,
        and tools we provide, you understand and agree to accept and adhere to
        the following terms and conditions as stated in this policy (hereinafter
        referred to as the â€˜Agreementâ€™), along with the terms and
        conditions as stated in our Privacy Policy (please refer to the Privacy
        Policy section below for more information).
      </p>
      <p>
        We reserve the right to change this Agreement from time to time
        with/without notice. You acknowledge and agree that it is your
        responsibility to review this Agreement periodically to familiarize
        yourself with any modifications. Your continued use of this site after
        such modifications will constitute acknowledgment and agreement of the
        modified terms and conditions.{" "}
      </p>
      <p>
        PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS
        WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY
        ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.{" "}
      </p>
      <p>
        This Agreement governs your use of this website https://www.homble.in/
        (hereinafter referred to as the â€˜Websiteâ€™), Jaxy Technologies Pvt.
        Ltd.câ€™s offer of for purchase on this Website, or your purchase of
        available on this Website. This Agreement includes and incorporates by
        this reference, the policies and guidelines referred below. Jaxy
        Technologies Pvt. Ltd. reserves the right to change or revise the terms
        and conditions of this Agreement at any time by posting any changes or a
        revised Agreement on this Website. Jaxy Technologies Pvt. Ltd. will/will
        not alert you that changes or revisions have been made by indicating on
        the top of this Agreement the date it was last revised. The changed or
        revised Agreement will be effective immediately after it is posted on
        this Website. Your use of the Website following the posting of any such
        changes or of a revised Agreement will constitute your acceptance of any
        such changes or revisions. Jaxy Technologies Pvt. Ltd. encourages you to
        review this Agreement whenever you visit the Website to make sure that
        you understand the terms and conditions governing use of the Website.
        This Agreement does not alter in any way the terms or conditions of any
        other written agreement you may have with Jaxy Technologies Pvt. Ltd.
        for other products or services. If you do not agree to this Agreement
        (including any referenced policies or guidelines), please immediately
        terminate your use of the Website.{" "}
      </p>
      <h5>Responsible Use And Conduct</h5>
      <ul>
        <li>
          In order to access our Resources/ , you may be required to provide
          certain information about yourself (such as identification, email,
          phone number, contact details, etc.) as part of the registration
          process, or as part of your ability to use the Resources. You agree
          that any information you provide will always be accurate, correct, and
          up to date.
        </li>
        <li>
          You are responsible for maintaining the confidentiality of any login
          information associated with any account you use to access our
          Resources/ . Accordingly, you are responsible for all activities that
          occur under your accounts.
        </li>
        <li>
          Accessing (or attempting to access) any of our Resources/ by any means
          other than through the means we provide, is strictly prohibited. You
          specifically agree not to access (or attempt to access) any of our
          Resources/ through any automated, unethical or unconventional means.
        </li>
        <li>
          Engaging in any activity that disrupts or interferes with our
          Resources/ , including the servers and/or networks to which our
          Resources / are located or connected, is strictly prohibited.
        </li>
        <li>
          Attempting to copy, duplicate, reproduce, sell, trade, or resell our
          Resources / is strictly prohibited.
        </li>
        <li>
          You are solely responsible for any consequences, losses, or damages
          that we may directly or indirectly incur or suffer due to any
          unauthorized activities conducted by you, as explained above, and may
          incur criminal or civil liability.
        </li>
      </ul>
      <h5>Privacy:</h5>

      <p>
        Jaxy Technologies Pvt. Ltd. believes strongly in protecting user
        privacy, which is why a separate Privacy Policy have been created in
        order to explain in detail how we collect, manage, process, secure, and
        store your private information. Please refer to Jaxy Technologies Pvt.
        Ltd. privacy policy, incorporated by reference herein, that is posted on
        the Website.{" "}
      </p>
      <h5>Terms of Offer:</h5>

      <p>
        This Website offers for sale certain products and services. By placing
        an order for products/services through this Website, you agree to the
        terms set forth in this Agreement.{" "}
      </p>
      <h5>Customer Solicitation:</h5>

      <p>
        Unless you notify our third party call center representatives or direct
        Jaxy Technologies Pvt. Ltd. sales representatives, while they are
        calling you, of your desire to opt out from further direct company
        communications and solicitations, you are agreeing to continue to
        receive further emails and call solicitations from Jaxy Technologies
        Pvt. Ltd. and its designated in house or third party call team(s).{" "}
      </p>
      <h5>Opt Out Procedure:</h5>

      <p>We provide 3 easy ways to opt out of from future solicitations </p>
      <ol>
        <li>
          You may use the opt out link found in any email solicitation that you
          may receive.
        </li>
        <li>
          You may also choose to opt out, via sending your email address to:
          info@homble.in.{" "}
        </li>
        <li>
          You may send a written remove request to Jaxy Technologies Pvt. Ltd.
          sales representatives, while they are calling you, of your desire to
          opt out from further direct company communications and solicitations,
          you are agreeing to continue to receive further emails and call
          solicitations from Jaxy Technologies Pvt. Ltd. and ADDRESS No. 148,
          BBMP No. 170/148,,5th Main Road, Sector -6, HSR
          Layout,Bangalore,Karnataka,560102.
        </li>
      </ol>

      <h5>Proprietary Rights:</h5>

      <p>
        Jaxy Technologies Pvt. Ltd. has proprietary rights and trade secrets wrt
        our business . You may not copy, reproduce, resell or redistribute any
        Product manufactured and/or distributed by Jaxy Technologies Pvt. Ltd..
        Jaxy Technologies Pvt. Ltd. also has rights to all trademarks and trade
        dress and specific layouts of this webpage, including calls to action,
        text placement, images and other information.{" "}
      </p>

      <p>
        <strong>Taxes:</strong> If you purchase any products or services , you
        will be responsible for paying the applicable taxes. (GST){" "}
      </p>

      <h5>Content, Intellectual Property, Third Party Links: </h5>

      <p>
        In addition to making available products and services, www.homble.in
        also offers information and marketing materials. This Website also
        offers information, both directly and through indirect links to
        third-party websites, about (kind of information). Jaxy Technologies
        Pvt. Ltd. does not always create the information offered on this
        Website; instead the information is often gathered from other sources.
        To the extent that Jaxy Technologies Pvt. Ltd. does create the content
        on this Website, such content is protected by intellectual property laws
        of the India, foreign nations, and international bodies. Unauthorized
        use of the material may violate copyright, trademark, and/or other laws.
        You acknowledge that your use of the content on this Website is for
        personal, non-commercial use. Any links to third-party websites are
        provided solely as a convenience to you. Jaxy Technologies Pvt. Ltd.
        does not endorse the contents on any such third-party websites. Jaxy
        Technologies Pvt. Ltd. is not responsible for the content of or any
        damage that may result from your access to or reliance on these
        third-party websites. If you link to third-party websites, you do so at
        your own risk.{" "}
      </p>
      <h5>Use of Website:</h5>
      <p>
        Use of the Site is available only to persons who can legally enter into
        contracts under applicable laws. Persons who are "incompetent to
        contract", within the meaning of the Indian Contract Act, 1872 including
        un-discharged insolvents etc. are not eligible to use the Site. Jaxy
        Technologies Pvt. Ltd. reserves the right to terminate your access to
        the Site if it discovers that you are under the age of 18 years or
        suffers from any other disability, as recognized under Indian Contract
        Act, 1872.
      </p>

      <p>
        Jaxy Technologies Pvt. Ltd. is not responsible for any damages resulting
        from use of this website by anyone. You will not use the Website for
        illegal purposes. You will -{" "}
      </p>

      <ul>
        <li>
          abide by all applicable local, state, national, and international laws
          and regulations in your use of the Website (including laws regarding
          intellectual property),{" "}
        </li>
        <li>
          not interfere with or disrupt the use and enjoyment of the Website by
          other users,{" "}
        </li>
        <li>not resell material on the Website, </li>
        <li>
          not engage, directly or indirectly, in transmission of "spam", chain
          letters, junk mail or any other type of unsolicited communication, and{" "}
        </li>
        <li>
          not defame, harass, abuse, or disrupt other users of the Website.
        </li>
      </ul>
      <h5>License:</h5>

      <p>
        By using this Website, you are granted a limited, non-exclusive,
        non-transferable right to use the content and materials on the Website
        in connection with your normal, non-commercial use of the Website. You
        may not copy, reproduce, transmit, distribute, or create derivative
        works of such content or information without express written
        authorization from Jaxy Technologies Pvt. Ltd. or the applicable third
        party (if third party content is at issue).{" "}
      </p>
      <h5>Blogs/Reviews/Comments: </h5>
      <ul>
        <li>
          We may provide various open communication tools on our website, such
          as blog comments, blog posts, public chat, forums, message boards,
          newsgroups, product ratings and reviews, various social media
          services, etc. You understand that generally we do not pre-screen or
          monitor the content posted by users of these various communication
          tools, which means that if you choose to use these tools to submit any
          type of content to our website, then it is your personal
          responsibility to use these tools in a responsible and ethical manner.
          By posting information or otherwise using any open communication tools
          as mentioned, you agree that you will not upload, post, share, or
          otherwise distribute any content that:{" "}
        </li>
        <ul>
          <li>
            is illegal, threatening, defamatory, abusive, harassing, degrading,
            intimidating, fraudulent, deceptive, invasive, racist, or contains
            any type of suggestive, inappropriate, or explicit language;
          </li>
          <li>
            infringes on any trademark, patent, trade secret, copyright, or
            other proprietary right of any party;
          </li>
          <li>contains any type of unauthorized or unsolicited advertising;</li>
          <li>
            impersonates any person or entity, including any
            https://www.homble.in//Jaxy Technologies Pvt. Ltd. employees or
            representatives.
          </li>
        </ul>
        <li>
          We have the right at our sole discretion to remove any content that,
          we feel in our judgment does not comply with this User Agreement,
          along with any content that we feel is otherwise offensive, harmful,
          objectionable, inaccurate, or violates any 3rd party copyrights or
          trademarks. We are not responsible for any delay or failure in
          removing such content. If you post content that we choose to remove,
          you hereby consent to such removal, and consent to waive any claim
          against us.
        </li>
        <li>
          We do not assume any liability for any content posted by you or any
          other 3rd party users of our website. However, any content posted by
          you using any open communication tools on our website, provided that
          it doesn't violate or infringe on any 3rd party copyrights or
          trademarks, becomes the property of Jaxy Technologies Pvt. Ltd., and
          as such, gives us a perpetual, irrevocable, worldwide, royalty-free,
          exclusive license to reproduce, modify, adapt, translate, publish,
          publicly display and/or distribute as we see fit. This only refers and
          applies to content posted via open communication tools as described,
          and does not refer to information that is provided as part of the
          registration process, necessary in order to use our Resources. All
          information provided as part of our registration process is covered by
          our Privacy Policy.
        </li>
        <li>
          You agree to indemnify and hold harmless https://www.homble.in/ a
          digital property of Jaxy Technologies Pvt. Ltd. and its parent company
          and affiliates, and their directors, officers, managers, employees,
          donors, agents, and licensors, from and against all losses, expenses,
          damages and costs, including reasonable attorneys' fees, resulting
          from any violation of this User Agreement or the failure to fulfill
          any obligations relating to your account incurred by you or any other
          person using your account. We reserve the right to take over the
          exclusive defense of any claim for which we are entitled to
          indemnification under this User Agreement. In such event, you shall
          provide us with such cooperation as is reasonably requested by us.
        </li>
      </ul>
      <h5>Posting:</h5>

      <p>
        By posting, storing, or transmitting any content on the Website, you
        hereby grant Jaxy Technologies Pvt. Ltd. a perpetual, worldwide,
        non-exclusive, royalty-free, assignable, right and license to use, copy,
        display, perform, create derivative works from, distribute, have
        distributed, transmit and assign such content in any form, in all media
        now known or hereinafter created, anywhere in the world. Jaxy
        Technologies Pvt. Ltd. does not have the ability to control the nature
        of the user-generated content offered through the Website. You are
        solely responsible for your interactions with other users of the Website
        and any content you post. Jaxy Technologies Pvt. Ltd. is not liable for
        any damage or harm resulting from any posts by or interactions between
        users. Jaxy Technologies Pvt. Ltd. reserves the right, but has no
        obligation, to monitor interactions between and among users of the
        Website and to remove any content Jaxy Technologies Pvt. Ltd. deems
        objectionable, in Jaxy Technologies Pvt. Ltd.'s sole discretion.{" "}
      </p>
      <h5>Disclaimer of Warranties:</h5>

      <p>
        Your use of this website and/or are at your sole risk. The website and
        are offered on an "as is" and "as available" basis. Jaxy Technologies
        Pvt. Ltd. expressly disclaims all warranties of any kind, whether
        express or implied, including, but not limited to, implied warranties of
        merchantability, fitness for a particular purpose and non-infringement
        with respect to the or website content, or any reliance upon or use of
        the website content or (" " include trial .){" "}
      </p>
      <p>
        Without limiting the generality of the foregoing, Jaxy Technologies Pvt.
        Ltd. makes no warranty:
      </p>
      <ul>
        <li>
          that the information provided on this website is accurate, reliable,
          complete, or timely.
        </li>
        <li>
          that the links to third-party websites are to information that is
          accurate, reliable, complete, or timely.
        </li>
        <li>
          no advice or information, whether oral or written, obtained by you
          from this website will create any warranty not expressly stated herein
        </li>
        <li>
          as to the results that may be obtained from the use of the products or
          that defects in products will be corrected.
        </li>
        <li>regarding any purchased or obtained through the website.</li>
      </ul>
      <p>
        Some jurisdictions do not allow the exclusion of certain warranties, so
        some of the above exclusions may not apply to you.
      </p>
      <h5>Limitation Of Liability</h5>

      <p>
        Jaxy Technologies Pvt. Ltd.’s entire liability, and your exclusive
        remedy, in law, in equity, or otherwise, with respect to the website
        content / and/or for any breach of this agreement is solely limited to
        the amount you paid, less shipping and handling charges, for purchased
        via this website.{" "}
      </p>
      <p>
        Jaxy Technologies Pvt. Ltd. will not be liable for any direct, indirect,
        incidental, special or consequential damages in connection with this
        agreement &/or in any manner, including liabilities resulting from:{" "}
      </p>
      <ul>
        <li>the use or the inability to use the website content / ;</li>
        <li>the cost of procuring substitute content / ; </li>
        <li>
          any information obtained / purchased or transactions entered into
          through the website; or{" "}
        </li>
        <li>any lost profits you allege.</li>
      </ul>
      <p>
        Some jurisdictions do not allow the limitation or exclusion of liability
        for incidental or consequential damages so some of the above limitations
        may not apply to you.
      </p>
      <p>
        conjunction with the Limitation of Liability as explained above, you
        expressly understand and agree that any claim against us shall be
        limited to the amount you paid, if any, for use of .
        https://www.homble.in//Jaxy Technologies Pvt. Ltd. will not be liable
        for any direct, indirect, incidental, consequential or exemplary loss or
        damages which may be incurred by you as a result of using our Resources
        / , or as a result of any changes, data loss or corruption,
        cancellation, loss of access, or downtime to the full extent that
        applicable limitation of liability laws apply.
      </p>
      <h5>Indemnification:</h5>

      <p>
        You will release, indemnify, defend and hold harmless Jaxy Technologies
        Pvt Ltd, and any of its contractors, agents, employees, officers,
        directors, shareholders, affiliates and assigns from all liabilities,
        claims, damages, costs and expenses, including reasonable attorneys'
        fees and expenses, of third parties relating to or arising out of{" "}
      </p>
      <ul>
        <li>
          this Agreement or the breach of your warranties, representations and
          obligations under this Agreement;
        </li>
        <li>the Website content or your use of the Website content; </li>
        <li>the or your use of the (including Trial ); </li>
        <li>
          any intellectual property or other proprietary right of any person or
          entity;
        </li>
        <li>your violation of any provision of this Agreement; or </li>
        <li>
          any information or data you supplied to Jaxy Technologies Pvt Ltd.
        </li>
      </ul>
      <p>
        When Jaxy Technologies Pvt Ltd is threatened with suit or sued by a
        third party, Jaxy Technologies Pvt Ltd may seek written assurances from
        you concerning your promise to indemnify Jaxy Technologies Pvt Ltd; your
        failure to provide such assurances may be considered by Jaxy
        Technologies Pvt Ltd to be a material breach of this Agreement. Jaxy
        Technologies Pvt Ltd will have the right to participate in any defense
        by you of a third-party claim related to your use of any of the Website
        content / , with the counsel of Jaxy Technologies Pvt Ltdâ€™s choice at
        its expense. Jaxy Technologies Pvt Ltd will reasonably cooperate in any
        defense by you of a third-party claim at your request and expense. You
        will have sole responsibility to defend Jaxy Technologies Pvt Ltd
        against any claim, but you must receive Jaxy Technologies Pvt Ltdâ€™s
        prior written consent regarding any related settlement. The terms of
        this provision will survive any termination or cancellation of this
        Agreement or your use of the Website / .{" "}
      </p>
      <h5>Copyrights / Trademarks</h5>

      <p>
        All content and materials available on https://www.homble.in/, including
        but not limited to text, graphics, website name, code, images and logos
        are the intellectual property of Jaxy Technologies Pvt Ltd and are
        protected by applicable copyright and trademark law. Any inappropriate
        use, including but not limited to the reproduction, distribution,
        display or transmission of any content on this site is strictly
        prohibited, unless specifically authorized by Jaxy Technologies Pvt Ltd.{" "}
      </p>
      <h5>Agreement To Be Bound</h5>

      <p>
        BY USING THIS WEBSITE OR ORDERING , YOU ACKNOWLEDGE THAT YOU HAVE READ
        AND AGREE TO BE BOUND BY THIS AGREEMENT AND ALL TERMS AND CONDITIONS ON
        THIS WEBSITE.{" "}
      </p>
      <h5>General Clause:</h5>
      <ul>
        <li>
          <strong>Force Majeure:</strong> Jaxy Technologies Pvt Ltd will not be
          deemed in default hereunder or held responsible for any cessation,
          interruption or delay in the performance of its obligations hereunder
          due to earthquake, flood, fire, storm, natural disaster, act of God,
          war, terrorism, armed conflict, labor strike, lockout, or boycott.
        </li>
        <li>
          <strong>Cessation of Operation:</strong> Jaxy Technologies Pvt Ltd may
          at any time, in its sole discretion and without advance notice to you,
          cease operation of the Website and distribution of the .
        </li>
        <li>
          <strong>Entire Agreement:</strong> This Agreement comprises the entire
          agreement between you and Jaxy Technologies Pvt Ltd and supersedes any
          prior agreements pertaining to the subject matter contained herein.
        </li>
      </ul>
      <h5>Governing Law:</h5>

      <p>
        This website is controlled by Jaxy Technologies Pvt Ltd from our offices
        located in the state of Karnataka, India. It can be accessed by most
        countries around the world. As each country has laws that may differ
        from those of Karnataka, India, by accessing our website, you agree that
        the statutes and laws of Karnataka, India, without regard to its
        conflict of law principles to the contrary and the United Nations
        Convention on the International Sales of Goods, will apply to all
        matters relating to the use of this website and the purchase of any
        through this site.{" "}
      </p>
      <h5>Effect of Waiver:</h5>

      <p>
        The failure of Jaxy Technologies Pvt Ltd to exercise or enforce any
        right or provision of this Agreement will not constitute a waiver of
        such right or provision. If any provision of this Agreement is found by
        a court of competent jurisdiction to be invalid, the parties
        nevertheless agree that the court should endeavor to give effect to the
        parties' intentions as reflected in the provision and the other
        provisions of this Agreement remain in full force and effect.
      </p>
      <h5>Governing Law/Jurisdiction:</h5>

      <p>
        This Website originates from the Bangalore, Karnataka, India. This
        Agreement will be governed by the laws of the State of Karnataka, India.
        It can be accessed by most countries around the world. As each country
        has laws that may differ from those of Karnataka, India without regard
        to its conflict of law principles to the contrary. Neither you nor Jaxy
        Technologies Pvt Ltd will commenceKarnataka), India. This Agreement will
        be governed by the laws of the State of Karnataka, India. It can be
        accessed by most countries around the world. As each country has laws
        that may differ from those of Karnataka, India without regard to its
        conflict of law principles to the contrary. Neither you nor or prosecute
        any suit, proceeding or claim to enforce the provisions of this
        Agreement, to recover damages for breach of or default of this
        Agreement, or otherwise arising under or by reason of this Agreement,
        other than in courts located in State of Karnataka, India. It can be
        accessed by most countries around the world. As each country has laws
        that may differ from those of Karnataka, India. By using this Website or
        ordering , you consent to the jurisdiction and venue of such courts in
        connection with any action, suit, proceeding or claim arising under or
        by reason of this Agreement. You hereby waive any right to trial by jury
        arising out of this Agreement and any related documents.
      </p>
      <p>
        This website is controlled by Jaxy Technologies Pvt Ltd from our offices
        located in the state of Karnataka, India. It can be accessed by most
        countries around the world. As each country has laws that may differ
        from those of Karnataka, India, by accessing our website, you agree that
        the statutes and laws of Karnataka, India without regard to the conflict
        of laws and the United Nations Convention on the International Sales of
        Goods, will apply to all matters relating to the use of this website and
        the purchase of any through this site.
      </p>
      <p>
        Furthermore, any action to enforce this User Agreement shall be brought
        in the courts having jurisdiction over such issue, located in Karnataka,
        India. You hereby agree to judgement passed by such courts and waive any
        right to jurisdictional, venue, or inconvenient forum objections to such
        courts.
      </p>
      <h5>Statute of Limitation:</h5>

      <p>
        You agree that regardless of any statute or law to the contrary, any
        claim or cause of action arising out of or related to use of the Website
        or or this Agreement must be filed within one (1) year after such claim
        or cause of action arose else be forever barred.{" "}
      </p>
      <h5>Waiver of Class Action Rights:</h5>

      <p>
        BY ENTERING INTO THIS AGREEMENT, YOU HEREBY IRREVOCABLY WAIVE ANY RIGHT
        YOU MAY HAVE TO JOIN CLAIM WITH THOSE OF OTHERS IN THE FORM OF A CLASS
        ACTION OR SIMILAR PROCEDURAL DEVICE. ANY CLAIMS ARISING OUT OF OR
        RELATING TO OR IN CONNECTION WITH THIS AGREEMENT MUST BE ASSERTED
        INDIVIDUALLY.{" "}
      </p>
      <h5>Termination: </h5>

      <p>
        Jaxy Technologies Pvt Ltd reserves the right to terminate your access to
        the Website if it reasonably believes, in its sole discretion, that you
        have breached any of the terms and conditions of this Agreement.
        Following termination, you will not be permitted to use the Website and
        Jaxy Technologies Pvt Ltd may, in its sole discretion and without
        advance notice to you, cancel any outstanding orders for . If your
        access to the Website is terminated, Jaxy Technologies Pvt Ltd reserves
        the right to exercise whatever means it deems necessary to prevent
        unauthorized access of the Website. This Agreement will survive
        indefinitely unless and until Jaxy Technologies Pvt Ltd chooses, in its
        sole discretion and without advance notice to you, to terminate it.{" "}
      </p>
      <h5>Domestic Use:</h5>

      <p>
        Jaxy Technologies Pvt Ltd makes no representation that the Website or
        are appropriate or available for use in locations outside India. Users
        who access the Website from outside India do so at their own risk and
        initiative and must bear all responsibility for compliance with any
        applicable local laws.{" "}
      </p>
      <h5>Cancellation And Refund:</h5>

      <p>
        Cancellation of order is not possible once the payment has been made. No
        refunds will be given except in the event of cancellation or
        non-performance of service by Jaxy Technologies Pvt Ltd.{" "}
      </p>
      <h5>Guarantee:</h5>

      <p>
        Unless otherwise expressed, Jaxy Technologies Pvt Ltd and
        https://www.homble.in/ expressly disclaims all warranties and conditions
        of any kind, whether express or implied, including, but not limited to
        the implied warranties and conditions of merchantability, fitness of
        content / for a particular purpose and non-infringement.{" "}
      </p>
      <h5>Assignment: </h5>

      <p>
        You may not assign your rights and obligations under this Agreement to
        anyone. Jaxy Technologies Pvt Ltd may assign its rights and obligations
        under this Agreement in its sole discretion and without advance notice
        to you.{" "}
      </p>
      <p>
        BY USING THIS WEBSITE OR ORDERING FROM THIS WEBSITE YOU AGREE TO BE
        BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.
      </p>
      <h5>Contact Information:</h5>

      <p>
        If you have any questions or comments about these our Terms of Service
        as outlined above, you can contact us at:{" "}
      </p>
      <p>Jaxy Technologies Pvt Ltd</p>
      <p>
        No. 148, BBMP No. 170/148,,5th Main Road, Sector -6, HSR
        Layout,Bangalore,Karnataka,560102
      </p>
      <p>info@homble.in</p>
      <MobileFooter />
    </div>
  );
}

export default Termsscreen;
