import axios from "axios";
import {
  CREATABLE_LIST_REQUEST,
  CREATABLE_LIST_SUCCESS,
  CREATABLE_LIST_FAIL,
  CREATE_BATCHES_REQUEST,
  CREATE_BATCHES_SUCCESS,
  CREATE_BATCHES_FAIL,
  CREATE_BATCHES_RESET,
  BATCHES_LIST_REQUEST,
  BATCHES_LIST_SUCCESS,
  BATCHES_LIST_FAIL,
  BATCHES_LIST_UPDATE,
  INWARDING_LIST_REQUEST,
  INWARDING_LIST_SUCCESS,
  INWARDING_LIST_FAIL,
  MARK_RECD_REQUEST,
  MARK_RECD_SUCCESS,
  MARK_RECD_FAIL,
  MARK_RECD_RESET,
  NPI_CREATABLE_LIST_REQUEST,
  NPI_CREATABLE_LIST_SUCCESS,
  NPI_CREATABLE_LIST_FAIL,
  NPI_BATCHES_LIST_REQUEST,
  NPI_BATCHES_LIST_SUCCESS,
  NPI_BATCHES_LIST_FAIL,
  NPI_INWARDING_LIST_REQUEST,
  NPI_INWARDING_LIST_SUCCESS,
  NPI_INWARDING_LIST_FAIL,
  NPI_BATCH_APPROVE_REQUEST,
  NPI_BATCH_APPROVE_SUCCESS,
  NPI_BATCH_APPROVE_FAIL,
  BATCH_DELETE_REQUEST,
  BATCH_DELETE_SUCCESS,
  BATCH_DELETE_FAIL,
  BATCH_EDIT_REQUEST,
  BATCH_EDIT_SUCCESS,
  BATCH_EDIT_FAIL,
} from "../Constants/AdminConstants";

import { displayFlashMessage } from "./FlashMessageActions";
import scrollToTop from "../Utils/ScrollToTop";

export const getCreatablesList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATABLE_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.get(
      `hub/batches/creatable-batches/BAU/`,
      config
    );

    dispatch({ type: CREATABLE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CREATABLE_LIST_FAIL, payload: error.response });
  }
};

export const NPIgetCreatablesList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: NPI_CREATABLE_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.get(
      `hub/batches/creatable-batches/NPI/`,
      config
    );

    dispatch({ type: NPI_CREATABLE_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: NPI_CREATABLE_LIST_FAIL, payload: error.response });
  }
};

export const createBatches = (productList) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_BATCHES_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post(
      "hub/batches/create-batches/",
      productList,
      config
    );
    dispatch({ type: CREATE_BATCHES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CREATE_BATCHES_FAIL, payload: error.response });
  }
};
export const getBatchList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BATCHES_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.get(
      `hub/batches/seller-product-batches/ALL/`,
      config
    );

    dispatch({ type: BATCHES_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BATCHES_LIST_FAIL, payload: error.response });
  }
};

export const NPIgetBatchList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: NPI_BATCHES_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.get(
      `hub/batches/seller-product-batches/NPI/`,
      config
    );

    dispatch({ type: NPI_BATCHES_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: NPI_BATCHES_LIST_FAIL, payload: error.response });
  }
};

export const getInwardingList = (day) => async (dispatch, getState) => {
  try {
    dispatch({ type: INWARDING_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.get(
      `hub/batches/${day}s-batch-pickups/ALL/`,
      config
    );

    dispatch({ type: INWARDING_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: INWARDING_LIST_FAIL, payload: error.response });
  }
};
export const NPIgetInwardingList = (day) => async (dispatch, getState) => {
  try {
    dispatch({ type: NPI_INWARDING_LIST_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };

    const { data } = await axios.get(
      `hub/batches/${day}s-batch-pickups/NPI/`,
      config
    );

    dispatch({ type: NPI_INWARDING_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: NPI_INWARDING_LIST_FAIL, payload: error.response });
  }
};
export const markBatchReceived = (id, qty) => async (dispatch, getState) => {
  try {
    dispatch({ type: MARK_RECD_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post(
      `hub/batches/mark-batch-fulfilled/${id}/`,
      { fulfilledQuantity: Number(qty) },
      config
    );
    dispatch({ type: MARK_RECD_SUCCESS, payload: data });
    dispatch(
      displayFlashMessage({
        position: "middle-center",
        delay: 3000,
        background: "success",
        string: "Fulfilled Quantity Updated",
      })
    );
  } catch (error) {
    dispatch({ type: MARK_RECD_FAIL, payload: error.response });
    dispatch(
      displayFlashMessage({
        position: "top-center",
        delay: 3000,
        background: "danger",
        string:
          typeof error.response.data.detail === "string"
            ? error.response.data.detail
            : JSON.stringify(error.response.data.detail),
      })
    );
  }
};

export const batchDeleteAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BATCH_DELETE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { listBatches } = getState();
    const { BatchList } = listBatches;
    const { data } = await axios.delete(
      `hub/batches/delete-batch/${id}/`,
      config
    );
    dispatch({ type: BATCH_DELETE_SUCCESS, payload: data });
    dispatch(
      displayFlashMessage({
        position: "middle-center",
        delay: 3000,
        background: "success",
        string: "Deleted successfully",
      })
    );
  } catch (error) {
    dispatch({ type: BATCH_DELETE_FAIL, payload: error.response });
  }
};

export const batchEditAction = (id, qty) => async (dispatch, getState) => {
  try {
    dispatch({ type: BATCH_EDIT_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.patch(
      `hub/batches/edit-batch-capacity/${id}/`,
      { capacity: qty },
      config
    );
    dispatch({ type: BATCH_EDIT_SUCCESS, payload: data });
    dispatch(
      displayFlashMessage({
        position: "top-center",
        delay: 3000,
        background: "success",
        string: "Capacity Edited",
      })
    );
  } catch (error) {
    dispatch({ type: BATCH_EDIT_FAIL, payload: error.response });
  }
};

export const approveNPIBatch = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: NPI_BATCH_APPROVE_REQUEST });
    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;
    const config = {
      headers: {
        "Content-type": "Application/json",
        Authorization: "Token " + login.token,
      },
    };
    const { data } = await axios.post(
      `hub/batches/remove-hold/${id}/`,
      id,
      config
    );
    dispatch({ type: NPI_BATCH_APPROVE_SUCCESS, payload: data });
    scrollToTop();
    dispatch(
      displayFlashMessage({
        position: "top-center",
        delay: 3000,
        background: "success",
        string: "Batch Approved",
      })
    );
  } catch (error) {
    dispatch({ type: NPI_BATCH_APPROVE_FAIL, payload: error.response });
  }
};

/*

export const getProductDetails = (id) => async(dispatch,getState) => {
    try{
        dispatch({type: PRODUCT_DETAILS_REQUEST})

        const {userVerifyOTP} = getState()
        const {login} = userVerifyOTP

        const config = login ?{
                                headers:{'Content-type' :'Application/json',
                                        Authorization: 'Token '+login.token
                                        }
                                }
                            :""
        
        const {data} = await axios.get(`api/products/${id}/`,config)
        
        dispatch({type: PRODUCT_DETAILS_SUCCESS, payload:data})

    }catch(error){
        dispatch({type: PRODUCT_DETAILS_FAIL, 
                payload:error.response})
    }
}
export const getCategories = () => async(dispatch) => {

    try{
        dispatch({type: CATEGORY_LIST_REQUEST})

        const {data} = await axios.get('api/products/category-list/')
        dispatch({type: CATEGORY_LIST_SUCCESS,payload:data})

    }catch(error){
        dispatch({type:CATEGORY_LIST_FAIL,
            payload:error.response })
    }
}

export const getCategoryItems = (id) => async(dispatch,getState) => {
    try{
        dispatch({type: CATEGORY_DETAILS_REQUEST})

        const {userVerifyOTP} = getState()
        const {login} = userVerifyOTP

        const config = login ?{
                                headers:{'Content-type' :'Application/json',
                                        Authorization: 'Token '+login.token
                                        }
                                }
                            :""
        
        const {data} = await axios.get(`api/products/category/${id}/`,config)
        
        dispatch({type: CATEGORY_DETAILS_SUCCESS, payload:data})

    }catch(error){
        dispatch({type: CATEGORY_DETAILS_FAIL, 
                payload:error.response})
    }
}
*/
