import React, { useEffect } from "react";
import { Image, Carousel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getBanners } from "../Actions/ProductActions";
import { logEvent } from "firebase/analytics";
import { analytics } from "../Config/firebase";
import { homeEvents } from "../Constants/AnalyticsEvents";
import BannerItemSkeleton from "./BannerItemSkeleton";

const createBannerItem = (banner, width) => {
  const imageURL =
    width > 900
      ? banner.largeImage
      : width > 600
        ? banner.mediumImage
        : banner.smallImage;

  const height = width <= 600 ? 140 : 170;

  return {
    url: imageURL,
    height: height,
    redirectURL: banner?.redirectUrl,
    name: banner?.name,
  };
};

const Banner = (props) => {
  const { width } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const bannerList = useSelector((state) => state.bannerList);
  const { loading, error, banners } = bannerList;

  useEffect(() => {
    dispatch(getBanners());
  }, [dispatch]);

  if (error) {
    return <p>Banner</p>;
  }

  const marginTop = width < 768 ? -20 : -10;

  if (loading) {
    return (
      <div style={{ marginTop: marginTop }}>
        <BannerItemSkeleton width={width} />
      </div>
    );
  }

  return (
    <div style={{ marginTop: marginTop }}>
      <Carousel indicators={true} controls={false}>
        {banners?.banners?.map((item) => {
          const banner = createBannerItem(item, width);
          if (!banner) {
            return null;
          }
          const onClickHandler = () => {
            const redirectTo = banner.redirectURL.startsWith("/")
              ? banner.redirectURL
              : `/${banner.redirectURL}`;
            history.push(redirectTo);
            logEvent(analytics, homeEvents.CLICKED_BANNER, {
              redirectURL: banner?.redirectURL,
            });
          };
          return (
            <Carousel.Item
              key={`${banner.name}-${banner.redirectURL}`}
              onClick={onClickHandler}
            >
              <Image
                className="banner-image mx-auto d-block"
                style={{
                  height: banner.height,
                  cursor: "pointer",
                }}
                src={banner.url}
                alt={banner.name}
                fluid
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Banner;
