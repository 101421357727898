import React from "react";
import { ListGroup, Button } from "react-bootstrap";
import PortionSize from "../PortionSize";
import Rating from "../Rating";
import ProductMainDetails from "./ProductMainDetails";
import ProductReviews from "./ProductReviews";
import ProductActionButton from "./ProductActionButton";
import SellerInfo from "./SellerInfo";
import RecContainer from "../Recommendations/RecContainer";
import ProductDescription from "./ProductDescription";

const ProductDetails = (props) => {
  const {
    product,
    recommendations = [],
    showReport,
    handlepdfShow,
    handleReview,
    cartQty,
    handleQuantityChange,
    removeFromCartHandler,
    scrollToReviews,
  } = props;
  return (
    <ListGroup className="d-flex-col gap-2">
      <ProductMainDetails product={product} />
      {showReport && (
        <ListGroup.Item style={{ borderRadius: 8 }}>
          <Button size="sm" onClick={handlepdfShow} variant="secondary">
            Check Lab Report
          </Button>
        </ListGroup.Item>
      )}
      <ListGroup.Item
        className="d-flex justify-content-between align-items-center d-sm-none"
        style={{ borderRadius: 8 }}
      >
        <div>
          <p className="p-0 m-0 text-body-bold" style={{ fontSize: "0.9rem" }}>
            {<PortionSize packageSize={product.packageSize} />}{" "}
            {product.measurementUnit}
          </p>
          <p className="p-0 m-0 ">
            <span className="text-body-bold" style={{ color: "black" }}>
              &#8377;{product.price}{" "}
            </span>
            <span
              className="p-0 m-0 text-body-light"
              style={{ fontSize: "0.7rem" }}
            >
              (Inclusive of all taxes)
            </span>
          </p>
        </div>
        {product.quantityAvailable <= 0 ? (
          <p className="p-0 m-0 text-body-bold" style={{ fontSize: "0.9rem" }}>
            Sold out
          </p>
        ) : (
          <ProductActionButton
            product={product}
            cartQty={cartQty}
            changeQty={handleQuantityChange}
            removeFromCart={removeFromCartHandler}
            showCart={false}
          />
        )}
      </ListGroup.Item>
      <div style={{ marginBottom: 15 }}>
        <RecContainer
          rec={recommendations}
          heading="Frequently bought together"
          styles={{ background: "#f5f5f5" }}
          screen="PRODUCT"
        />
      </div>

      {product.ingredients && (
        <ListGroup.Item style={{ borderRadius: 8 }}>
          <p className="p-0 m-0 mb-1">
            <strong
              style={{ fontSize: "1.1rem" }}
              className="text-heading-bold"
            >
              Ingredients{" "}
            </strong>{" "}
          </p>
          <p className="p-0 m-0">{product.ingredients}</p>
        </ListGroup.Item>
      )}

      <ProductReviews
        product={product}
        handleReview={handleReview}
        scrollToReviews={scrollToReviews}
      />

      {/* <SellerInfo product={product} /> */}

      <ProductDescription product={product} />
      <ListGroup.Item style={{ borderRadius: 8 }}>
        {product.storage}
      </ListGroup.Item>

      <ListGroup.Item style={{ borderRadius: 8 }}>
        <p>
          <strong>Portion : </strong>{" "}
          {<PortionSize packageSize={product.packageSize} />}{" "}
          {product.measurementUnit}
        </p>
      </ListGroup.Item>
      {product.userOrderItems &&
      product.userOrderItems.find((item) => item.review) ? (
        <ListGroup.Item>
          You rated this item{" "}
          <Rating
            value={
              product.userOrderItems.find((item) => item.review).review.rating
            }
            text={""}
            color="orange"
          />
          You reviewed this item
          <p>
            <strong>
              {product.userOrderItems.find((item) => item.review).review.title}
            </strong>
          </p>
        </ListGroup.Item>
      ) : null}
    </ListGroup>
  );
};

export default ProductDetails;
