export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_ADD_FAIL = "CART_ADD_FAIL";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const CART_CLEAR_ITEMS = "CART_CLEAR_ITEMS";
export const CART_ITEMS_UPDATE = "CART_ITEMS_UPDATE";

export const CART_UPDATE_REQUEST = "CART_UPDATE_REQUEST";
export const CART_UPDATE_SUCCESS = "CART_UPDATE_SUCCESS";
export const CART_UPDATE_FAIL = "CART_UPDATE_FAIL";
export const CART_UPDATE_RESET = "CART_UPDATE_RESET";

export const CART_LIST_REQUEST = "CART_LIST_REQUEST";
export const CART_LIST_SUCCESS = "CART_LIST_SUCCESS";
export const CART_LIST_FAIL = "CART_LIST_FAIL";
export const CART_LIST_UPDATE = "CART_LIST_UPDATE";

export const CART_SAVE_SHIPPING_ADDRESS = "CART_SAVE_SHIPPING_ADDRESS";
export const CART_SAVE_PAYMENT_METHOD = "CART_SAVE_PAYMENT_METHOD";

export const SHIPPING_ADDRESS_LIST_REQUEST = "SHIPPING_ADDRESS_LIST_REQUEST";
export const SHIPPING_ADDRESS_LIST_SUCCESS = "SHIPPING_ADDRESS_LIST_SUCCESS";
export const SHIPPING_ADDRESS_LIST_FAIL = "SHIPPING_ADDRESS_LIST_FAIL";

export const SHIPPING_ADDRESS_CREATE_REQUEST =
  "SHIPPING_ADDRESS_CREATE_REQUEST";
export const SHIPPING_ADDRESS_CREATE_SUCCESS =
  "SHIPPING_ADDRESS_CREATE_SUCCESS";
export const SHIPPING_ADDRESS_CREATE_FAIL = "SHIPPING_ADDRESS_CREATE_FAIL";
export const SHIPPING_ADDRESS_CREATE_RESET = "SHIPPING_ADDRESS_CREATE_RESET";

export const SHIPPING_ADDRESS_DETAILS_REQUEST =
  "SHIPPING_ADDRESS_DETAILS_REQUEST";
export const SHIPPING_ADDRESS_DETAILS_SUCCESS =
  "SHIPPING_ADDRESS_DETAILS_SUCCESS";
export const SHIPPING_ADDRESS_DETAILS_FAIL = "SHIPPING_ADDRESS_DETAILS_FAIL";
export const SHIPPING_ADDRESS_DETAILS_RESET = "SHIPPING_ADDRESS_DETAILS_RESET";

export const APARTMENT_LIST_REQUEST = "APARTMENT_LIST_REQUEST";
export const APARTMENT_LIST_SUCCESS = "APARTMENT_LIST_SUCCESS";
export const APARTMENT_LIST_FAIL = "APARTMENT_LIST_FAIL";

export const APARTMENT_ADDRESS_CREATE_REQUEST =
  "APARTMENT_ADDRESS_CREATE_REQUEST";
export const APARTMENT_ADDRESS_CREATE_SUCCESS =
  "APARTMENT_ADDRESS_CREATE_SUCCESS";
export const APARTMENT_ADDRESS_CREATE_FAIL = "APARTMENT_ADDRESS_CREATE_FAIL";
export const APARTMENT_ADDRESS_CREATE_RESET = "APARTMENT_ADDRESS_CREATE_RESET";

export const DELIVERY_OPTIONS_REQUEST = "DELIVERY_OPTIONS_REQUEST";
export const DELIVERY_OPTIONS_SUCCESS = "DELIVERY_OPTIONS_SUCCESS";
export const DELIVERY_OPTIONS_FAIL = "DELIVERY_OPTIONS_FAIL";

export const IS_CUSTOMER_POD_ALLOLWED_REQUEST =
  "IS_CUSTOMER_POD_ALLOLWED_REQUEST";
export const IS_CUSTOMER_POD_ALLOLWED_SUCCESS =
  "IS_CUSTOMER_POD_ALLOLWED_SUCCESS";
export const IS_CUSTOMER_POD_ALLOLWED_FAIL = "IS_CUSTOMER_POD_ALLOLWED_FAIL";
