import { useState, useEffect, useMemo } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getCartList, clearCartList } from "../Actions/CartActions";
import Loader from "../Components/Loader";
import Errorhandle from "../Components/ErrorHandle";
import { checkDiscountCode } from "../Actions/OrderActions";
import CartBody from "../Components/Cart/CartBody";
import RecContainer from "../Components/Recommendations/RecContainer";
import {
  calculateDiscount,
  calculateTotalAmount,
  calculateTotalItems,
  filterCartProducts,
} from "../Utils/cartHelpers";
import ButtonContainer from "../Components/Cart/ButtonContainer";
import { getRecommendationsCart } from "../Actions/ProductActions";
import DiscountContainer from "../Components/Cart/DiscountContainer";
import { colors } from "../Constants/colors";
import useDocumentTitle from "../CustomHooks/useDocumentTitle";
import usePageTracking from "../CustomHooks/usePageTracking";

function NewCartscreen({ history }) {
  const [discountCode, setDiscountCode] = useState("");
  const [isCodeValid, setIsCodeValid] = useState(true);
  const [recItems, setRecItems] = useState([]);

  const dispatch = useDispatch();

  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const cartItemsList = useSelector((state) => state.cartItemsList);
  const { loading, success, slots, error } = cartItemsList;

  const orderDiscount = useSelector((state) => state.orderDiscount);
  const cartRecommendations = useSelector((state) => state.cartRecommendations);

  const {
    items: cartRec,
    loading: loadingRec,
    error: errorRec,
  } = cartRecommendations;

  const {
    loading: loadingDiscount,
    discountCoupon,
    error: errorDiscount,
  } = orderDiscount;

  useDocumentTitle(`Homble | Cart`);
  usePageTracking("Cart Screen");

  useEffect(() => {
    localStorage.getItem("cartItemsNew") &&
      localStorage.removeItem("cartItemsNew");
  }, []);

  useEffect(() => {
    Boolean(cartItems && cartItems.length > 0) &&
      dispatch(
        getCartList({
          productList: cartItems.map((item) => {
            return { id: item.id, quantity: item.quantityPossible };
          }),
        })
      );
    dispatch(getRecommendationsCart());
  }, [dispatch]);

  useEffect(() => {
    const items = filterCartProducts(cartItems, cartRec?.products);
    setRecItems(items);
  }, [cartItems, loadingRec]);

  const handleCheckout = () => {
    dispatch(clearCartList());
    history.push("/login?redirect=shipping");
  };

  const handleCode = () => {
    if (Boolean(discountCode)) {
      setIsCodeValid(true);
      dispatch(checkDiscountCode(discountCode));
    } else {
      setIsCodeValid(false);
    }
  };

  const totalItems = useMemo(() => calculateTotalItems(cartItems), [cartItems]);
  const totalAmount = useMemo(
    () => calculateTotalAmount(cartItems),
    [cartItems]
  );
  const discountAmount = useMemo(
    () => calculateDiscount(totalAmount, discountCoupon),
    [totalAmount, discountCoupon]
  );
  const balanceToPay = totalAmount - discountAmount;

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return (
    <>
      <h2>Shopping Cart</h2>
      {loading ? (
        <Loader />
      ) : error ? (
        <Errorhandle />
      ) : success && Boolean(cartItems && cartItems.length > 0) ? (
        <Row>
          <Col xs={12} sm={7}>
            <p>
              Showing <strong>earliest</strong> delivery times
            </p>
            <CartBody slots={slots} cartItems={cartItems} />
          </Col>
          <Col xs={12} sm={5}>
            <Row>
              {recItems?.length > 0 && (
                <Col xs={12}>
                  <RecContainer
                    rec={recItems}
                    heading={"Handpicked for you"}
                    screen="CART"
                    styles={{
                      background: colors.beige,
                      border: "1px solid lightGray",
                    }}
                  />
                </Col>
              )}
              <Col xs={12}>
                <div className="mt-2 mt-md-0">
                  <DiscountContainer
                    discountCoupon={discountCoupon}
                    login={login}
                    discountCode={discountCode}
                    setDiscountCode={setDiscountCode}
                    handleCode={handleCode}
                    isCodeValid={isCodeValid}
                    errorDiscount={errorDiscount}
                  />
                </div>
              </Col>
              <Col xs={12} style={{ marginTop: 15 }}>
                <div id="actionButtons">
                  <ButtonContainer
                    totalItems={totalItems}
                    totalAmount={totalAmount}
                    discountCoupon={discountCoupon}
                    discountAmount={discountAmount}
                    balanceToPay={balanceToPay}
                    cartItems={cartItems}
                    handleCheckout={handleCheckout}
                    history={history}
                    width={width}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        "No items in your cart"
      )}
    </>
  );
}

export default NewCartscreen;
