export const USER_GENERATE_OTP_REQUEST = "USER_GENERATE_OTP_REQUEST";
export const USER_GENERATE_OTP_SUCCESS = "USER_GENERATE_OTP_SUCCESS";
export const USER_GENERATE_OTP_FAIL = "USER_GENERATE_OTP_FAIL";
export const USER_GENERATE_OTP_RESET = "USER_GENERATE_OTP_RESET";

export const USER_VERIFY_OTP_REQUEST = "USER_VERIFY_OTP_REQUEST";
export const USER_VERIFY_OTP_SUCCESS = "USER_VERIFY_OTP_SUCCESS";
export const USER_VERIFY_OTP_FAIL = "USER_VERIFY_OTP_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_PROFILE_REQUEST = "USER_PROFILE_REQUEST";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_FAIL = "USER_PROFILE_FAIL";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL";
export const USER_LOGOUT_RESET = "USER_LOGOUT_RESET";
