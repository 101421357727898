export const CREATABLE_LIST_REQUEST = "CREATABLE_LIST_REQUEST";
export const CREATABLE_LIST_SUCCESS = "CREATABLE_LIST_SUCCESS";
export const CREATABLE_LIST_FAIL = "CREATABLE_LIST_FAIL";
export const CREATABLE_LIST_UPDATE = "CREATABLE_LIST_UPDATE";

export const CREATE_BATCHES_REQUEST = "CREATE_BATCHES_REQUEST";
export const CREATE_BATCHES_SUCCESS = "CREATE_BATCHES_SUCCESS";
export const CREATE_BATCHES_FAIL = "CREATE_BATCHES_FAIL";
export const CREATE_BATCHES_RESET = "CREATE_BATCHES_RESET";

export const BATCHES_LIST_REQUEST = "BATCHES_LIST_REQUEST";
export const BATCHES_LIST_SUCCESS = "BATCHES_LIST_SUCCESS";
export const BATCHES_LIST_FAIL = "BATCHES_LIST_FAIL";
export const BATCHES_LIST_UPDATE = "BATCHES_LIST_UPDATE";

export const BATCH_DELETE_REQUEST = "BATCH_DELETE_REQUEST";
export const BATCH_DELETE_SUCCESS = "BATCH_DELETE_SUCCESS";
export const BATCH_DELETE_FAIL = "BATCH_DELETE_FAIL";

export const BATCH_EDIT_REQUEST = "BATCH_EDIT_REQUEST";
export const BATCH_EDIT_SUCCESS = "BATCH_EDIT_SUCCESS";
export const BATCH_EDIT_FAIL = "BATCH_EDIT_FAIL";
export const BATCH_EDIT_RESET = "BATCH_EDIT_RESET";

export const INWARDING_LIST_REQUEST = "INWARDING_LIST_REQUEST";
export const INWARDING_LIST_SUCCESS = "INWARDING_LIST_SUCCESS";
export const INWARDING_LIST_FAIL = "INWARDING_LIST_FAIL";

export const MARK_RECD_REQUEST = "MARK_RECD_REQUEST";
export const MARK_RECD_SUCCESS = "MARK_RECD_SUCCESS";
export const MARK_RECD_FAIL = "MARK_RECD_FAIL";
export const MARK_RECD_RESET = "MARK_RECD_RESET";

export const NPI_CREATABLE_LIST_REQUEST = "NPI_CREATABLE_LIST_REQUEST";
export const NPI_CREATABLE_LIST_SUCCESS = "NPI_CREATABLE_LIST_SUCCESS";
export const NPI_CREATABLE_LIST_FAIL = "NPI_CREATABLE_LIST_FAIL";
export const NPI_CREATABLE_LIST_UPDATE = "NPI_CREATABLE_LIST_UPDATE";

export const NPI_BATCHES_LIST_REQUEST = "NPI_BATCHES_LIST_REQUEST";
export const NPI_BATCHES_LIST_SUCCESS = "NPI_BATCHES_LIST_SUCCESS";
export const NPI_BATCHES_LIST_FAIL = "NPI_BATCHES_LIST_FAIL";

export const NPI_INWARDING_LIST_REQUEST = "NPI_INWARDING_LIST_REQUEST";
export const NPI_INWARDING_LIST_SUCCESS = "NPI_INWARDING_LIST_SUCCESS";
export const NPI_INWARDING_LIST_FAIL = "NPI_INWARDING_LIST_FAIL";

export const NPI_BATCH_APPROVE_REQUEST = "NPI_BATCH_APPROVE_REQUEST";
export const NPI_BATCH_APPROVE_SUCCESS = "NPI_BATCH_APPROVE_SUCCESS";
export const NPI_BATCH_APPROVE_FAIL = "NPI_BATCH_APPROVE_FAIL";
