import React from "react";
import Form from "react-bootstrap/Form";
import {
  GENERAL_ISSUES_LIST,
  ORDER_ISSUES_LIST,
} from "../../Constants/IssueScreenConstants";

const IssueSelect = (props) => {
  const { setSelectIssue, orderId } = props;
  return (
    <Form.Group className="mb-3" controlId="issue">
      <Form.Label>Select your issue</Form.Label>
      <Form.Select
        type="text"
        onChange={(e) => setSelectIssue(e.target.value)}
        required
      >
        <option key="default" value="">
          Choose issue type
        </option>
        {orderId > 0
          ? ORDER_ISSUES_LIST.map((issue) => (
              <option key={issue.key} value={issue.key}>
                {issue.value}
              </option>
            ))
          : GENERAL_ISSUES_LIST.map((issue) => (
              <option key={issue.key} value={issue.key}>
                {issue.value}
              </option>
            ))}
      </Form.Select>
    </Form.Group>
  );
};

export default IssueSelect;
