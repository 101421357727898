import React from "react";
import { logEvent } from "firebase/analytics";
import { useHistory } from "react-router-dom";
import { analytics } from "../../Config/firebase";
import { homeEvents } from "../../Constants/AnalyticsEvents";

const CollectionTile = (props) => {
  const { item } = props;
  const { id, name, tileImage } = item ? item : Array(3).fill(null);
  const history = useHistory();

  const onClickHandler = () => {
    history.push(`/collection/${id}/${name}`);
    logEvent(analytics, homeEvents.CLICKED_COLLECTION_TILE, {
      id: id,
      name: name,
    });
  };

  return (
    <div className="p-0 m-0" onClick={onClickHandler}>
      <img
        src={tileImage}
        alt={name}
        style={{ width: "120px", borderRadius: 10 }}
      />
    </div>
  );
};

export default CollectionTile;
