import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCollectionItems } from "../Actions/ProductActions";
import Banner from "../Components/Banner";
import FlashMessage from "../Components/FlashMessage";
import Loader from "../Components/Loader";
import ErrorHandle from "../Components/ErrorHandle";
import { Row, Col, Button } from "react-bootstrap";
import Product from "../Components/ProductCard/Product";
import { getSortedCollectionItems } from "../Utils/CategoryFunctions";
import useDocumentTitle from "../CustomHooks/useDocumentTitle";
import usePageTracking from "../CustomHooks/usePageTracking";

const CollectionScreen = ({ history, match }) => {
  const dispatch = useDispatch();

  const collectionId = match.params.id;
  const collectionName = match.params.name;
  const [showToast, setShowToast] = useState(false);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [showRecContainer, setShowRecContainer] = useState(null);

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const collectionDetails = useSelector((state) => state.collectionDetails);
  const { collectionData, loading, error } = collectionDetails;

  useDocumentTitle(`Homble | ${collectionName}`);
  usePageTracking("Collection Screen");

  useEffect(() => {
    dispatch(getCollectionItems(collectionId));
  }, [dispatch, collectionId]);

  useEffect(() => {
    const items = getSortedCollectionItems(collectionData?.products);
    setSortedProducts(items);
  }, [collectionData]);

  const handleHome = () => {
    history.push("/");
  };

  return (
    <>
      <FlashMessage
        show={showToast}
        message={"Sorry! No more stock"}
        setShow={setShowToast}
        background={"primary"}
        position="bottom-center"
      />
      <Banner width={width} />
      {loading ? (
        <Loader />
      ) : error ? (
        <ErrorHandle history={history} error={error} />
      ) : collectionData?.products && collectionData?.products.length !== 0 ? (
        <>
          <Button variant="secondary" onClick={handleHome} className="mt-2">
            Back Home
          </Button>
          <h3 className="my-3">Shop for {collectionName}</h3>
          <Row>
            {sortedProducts?.map(
              (product) =>
                product.earliestDeliverySlot && (
                  <Col
                    key={`column-${product.id}`}
                    xs={12}
                    md={6}
                    lg={6}
                    xl={4}
                  >
                    {product.showStock ? (
                      <Product
                        key={product.id}
                        product={product}
                        setShowToast={setShowToast}
                        showRecContainer={showRecContainer}
                        setShowRecContainer={setShowRecContainer}
                        screen="COLLECTION"
                      />
                    ) : null}
                  </Col>
                )
            )}
          </Row>
        </>
      ) : (
        <h5 className="mt-2">Nothing on sale in this category currently</h5>
      )}
    </>
  );
};

export default CollectionScreen;
