import React from "react";
import RecItem from "./RecItem";
import "../../Styles/Recommendations.css";
import RecContainerSkeleton from "./RecContainerSkeleton";

const RecContainer = ({
  rec,
  heading = "",
  styles = {},
  screen,
  innerClasses = "",
  onItemClick = null,
  loading = false,
}) => {
  if (loading) {
    return (
      <RecContainerSkeleton
        numItems={8}
        styles={styles}
        innerClasses={innerClasses}
      />
    );
  }
  if (!rec || rec.length < 1) {
    return null;
  }
  return (
    <div
      style={{
        marginTop: 15,
        padding: 10,
        borderRadius: 10,
        width: "100%",
        ...styles,
      }}
    >
      <p className="text-heading-secondary p-0 m-0 mb-1">{heading}</p>
      <div
        className={`d-flex rec-container ${innerClasses}`}
        style={{ gap: 2 }}
      >
        {rec.map((item) => (
          <RecItem
            key={item.id}
            item={item}
            screen={screen}
            onItemClick={onItemClick}
          />
        ))}
      </div>
    </div>
  );
};

export default RecContainer;
