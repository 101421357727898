import React, { useCallback, useEffect, useState } from "react";
import FlashMessage from "../Components/FlashMessage";
import SearchContainer from "../Components/Search/SearchContainer";
import { getSearchList } from "../Actions/ProductActions";
import { useDispatch, useSelector } from "react-redux";
import useDocumentTitle from "../CustomHooks/useDocumentTitle";
import useInfiniteScroll from "../CustomHooks/useInfiniteScroll";
import { PRODUCT_LIST_RESET } from "../Constants/ProductConstants";
import SearchPageHeader from "../Components/Search/SearchPageHeader";
import { getDeviceType } from "../Utils/layoutHelpers";
import usePageTracking from "../CustomHooks/usePageTracking";

const SearchScreen = ({ history }) => {
  const [showToast, setShowToast] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dispatch = useDispatch();

  const { products, pagination, loading, error } = useSelector(
    (state) => state.productList
  );

  let keyword = history.location.search;
  const params = new URLSearchParams(keyword);
  const keywordValue = params.get("keyword");

  const loadMoreProducts = useCallback(() => {
    if (pagination && pagination.productsCount) {
      const totalPages = Math.ceil(pagination.productsCount / 30);
      if (!loading && currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  }, [loading, pagination]);

  useDocumentTitle("Homble | Whole Foods");
  usePageTracking("Search Screen");
  useInfiniteScroll(loading, loadMoreProducts);

  useEffect(() => {
    dispatch({ type: PRODUCT_LIST_RESET });
    setCurrentPage(1);
  }, [keywordValue]);

  useEffect(() => {
    if (
      currentPage === 1 ||
      currentPage < Math.ceil(pagination?.productsCount / 30)
    )
      keywordValue && dispatch(getSearchList(keywordValue, currentPage));
  }, [dispatch, keywordValue, currentPage]);

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const deviceType = getDeviceType(width);

  return (
    <div>
      <FlashMessage
        show={showToast}
        message={"Sorry! No more stock"}
        setShow={setShowToast}
        background={"primary"}
        position="bottom-center"
      />
      <SearchPageHeader deviceType={deviceType} />
      <SearchContainer
        history={history}
        products={products}
        keyword={keyword}
        loading={loading}
        error={error}
        setShowToast={setShowToast}
        deviceType={deviceType}
      />
    </div>
  );
};

export default SearchScreen;
