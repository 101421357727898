import { useEffect, useState } from "react";
import { Row, Col, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { newConfirmOrder } from "../Actions/OrderActions";
import Loader from "../Components/Loader";
import ErrorHandle from "../Components/ErrorHandle";
import { ORDER_NEWCONFIRM_RESET } from "../Constants/OrderConstants";
import { getStringSlot } from "../Utils/SlotFunction";
import {
  getFromLocalStorage,
  removeFromLocalStorage,
} from "../Utils/browserHelper";
import { SELECTED_SLOT_DATA } from "../Constants/BrowserConstants";
import OnDemandConfirmModal from "../Components/OrderConfirm/OnDemandConfirmModal";
import usePageTracking from "../CustomHooks/usePageTracking";

function OrderConfirmationscreen({ match, history }) {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const onCancelHandler = () => {
    setShowConfirmModal(false);
  };

  const orderNewConfirmation = useSelector(
    (state) => state.orderNewConfirmation
  );
  const { loading, error, confirmOrderDetails } = orderNewConfirmation;

  usePageTracking("Order Confirm Screen");

  useEffect(() => {
    dispatch({ type: ORDER_NEWCONFIRM_RESET });
    dispatch(newConfirmOrder(match.params.id));
    const slotData = getFromLocalStorage(SELECTED_SLOT_DATA);
    if (slotData?.slots?.some((slotObj) => slotObj?.slot?.type === 100)) {
      setShowConfirmModal(true);
    }
    removeFromLocalStorage(SELECTED_SLOT_DATA);
  }, [dispatch, match.params.id]);

  return loading ? (
    <Loader />
  ) : error ? (
    <ErrorHandle error={error} history={history} />
  ) : Object.keys(confirmOrderDetails).length > 0 ? (
    <>
      <h2>Order Confirmed</h2>
      <Row>
        <Col xs={12} md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h4>Deliver to:</h4>
              {Boolean(
                confirmOrderDetails.orders &&
                  confirmOrderDetails.orders.length > 0
              ) ? (
                <p>
                  <strong>{confirmOrderDetails.orders[0].address.name}</strong>
                  <br />
                  {"  "}
                  {confirmOrderDetails.orders[0].address.doorNum},{" "}
                  {confirmOrderDetails.orders[0].address.buildingDetails}
                  {"  "}
                  {confirmOrderDetails.orders[0].address.road},{" "}
                  {confirmOrderDetails.orders[0].address.area},{"  "}
                  {confirmOrderDetails.orders[0].address.city},{" "}
                  {confirmOrderDetails.orders[0].address.pincode}
                  <br /> Contact No:{" "}
                  {confirmOrderDetails.orders[0].address.mobile}
                </p>
              ) : (
                <p>No address found</p>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h4>Your Orders</h4>
              {confirmOrderDetails.status === "POD pending" ? (
                <>
                  <p>
                    Amount to be paid on delivery :{" "}
                    <span style={{ color: "#d9230f" }}>
                      {" "}
                      &#8377; {confirmOrderDetails.netPayable}
                    </span>
                  </p>
                </>
              ) : confirmOrderDetails.paymentStatus === "Completed" ? (
                <p>
                  Payment &#8377; {confirmOrderDetails.netPayable} completed
                </p>
              ) : (
                <p>Amount Paid : &#8377; {confirmOrderDetails.netPayable}</p>
              )}
              <p>
                <i>
                  Track status in <Link to="/myorders">My Orders</Link> page
                </i>
              </p>
              {confirmOrderDetails.orders.map((order) => (
                <ListGroup key={order.id}>
                  <ListGroup.Item>
                    <strong>Order #{order.id}</strong>
                  </ListGroup.Item>
                  <ListGroup.Item style={{ color: "blue" }}>
                    Delivery {getStringSlot(order.deliverySlot)}
                  </ListGroup.Item>
                  {order.items.map((item) => (
                    <ListGroup.Item key={item.id}>
                      <Row>
                        <Col xs={10} md={8}>
                          {item.name}
                        </Col>

                        <Col xs={2} md={3}>
                          x {item.quantity}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                  <ListGroup.Item>
                    <p>Delivery Fee : {order.deliveryFee}</p>
                  </ListGroup.Item>
                </ListGroup>
              ))}
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
      <br />
      {showConfirmModal && (
        <OnDemandConfirmModal
          showModal={showConfirmModal}
          onSecondaryHandler={onCancelHandler}
        />
      )}
    </>
  ) : (
    <p>Order details not found</p>
  );
}

export default OrderConfirmationscreen;
