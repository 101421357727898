import React, { useEffect } from "react";
import { getCollectionWithWithout } from "../../Actions/ProductActions";
import { useDispatch, useSelector } from "react-redux";
import CollectionScroll from "./CollectionScroll";
import "../../Styles/collections.css";
import CollectionMadeTypeSkeleton from "./CollectionMadeTypeSkeleton";

const CollectionsMadeType = () => {
  const dispatch = useDispatch();
  const { collections, loading } = useSelector(
    (state) => state.collectionMadeType
  );
  useEffect(() => {
    dispatch(getCollectionWithWithout());
  }, [dispatch]);

  if (loading) {
    return <CollectionMadeTypeSkeleton />;
  }

  if (!collections || Object.keys(collections).length < 1) {
    return null;
  }
  return (
    <div className={`mt-4 stretch-component-full d-flex flex-column gap-2`}>
      <h4 className="text-heading-primary" style={{ paddingLeft: 10 }}>
        Explore Collections
      </h4>
      {
        <CollectionScroll
          title={"Made Without"}
          collections={collections?.madeWithout}
          backgroundColor={"#641226"}
          loading={loading}
        />
      }
      {
        <CollectionScroll
          title={"Made With"}
          collections={collections?.madeWith}
          backgroundColor={"#163832"}
          loading={loading}
        />
      }
    </div>
  );
};

export default CollectionsMadeType;
