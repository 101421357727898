import React from "react";

const BackButton = ({ onClick }) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer", padding: 5 }}>
      <i className="fas fa-chevron-left p-0 m-0" />
    </div>
  );
};

export default BackButton;
