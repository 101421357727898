import React, { useState } from "react";
import SubCategoryTile from "./SubCategoryTile";
import { trackEvent } from "../../Config/amplitude";

const SubCategoryList = ({ history, categoryData }) => {
  const DEFAULT_ITEM = { id: 0, name: "All", image: categoryData?.image };

  const [selectedSid, setSelectedSid] = useState(0);

  const onSubcategoryClickHandler = (sid, subName) => {
    history.replace(
      `/category/${categoryData?.id}/${categoryData?.name}/${sid}/${subName}`
    );
    setSelectedSid(sid);
    trackEvent("Clicked Sub-category", { id: sid, name: subName });
  };

  return (
    <div className="pl-2 gap-1 d-flex flex-column">
      {[DEFAULT_ITEM, ...(categoryData?.subcategories || [])]?.map((item) => {
        return (
          <SubCategoryTile
            item={item}
            onSubcategoryClickHandler={onSubcategoryClickHandler}
            selectedSid={selectedSid}
          />
        );
      })}
    </div>
  );
};

export default SubCategoryList;
