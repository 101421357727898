import React from "react";
import { Route } from "react-router-dom";
import Homescreen from "./Screens/Homescreen";
import Productscreen from "./Screens/Productscreen";
import Aboutscreen from "./Screens/Aboutscreen";
import CheckoutScreenNew from "./Screens/CheckoutScreenNew";
import Onboardingscreen from "./Screens/Onboardingscreen";
import Loginscreen from "./Screens/Loginscreen";
import Verifyscreen from "./Screens/Verifyscreen";
import Profilescreen from "./Screens/Profilescreen";
import Privacyscreen from "./Screens/Privacyscreen";
import Termsscreen from "./Screens/Termsscreen";
import Contactscreen from "./Screens/Contactscreen";
import Shippingscreen from "./Screens/Shippingscreen";
import ShippingPolicyscreen from "./Screens/ShippingPolicyscreen";
import Reviewscreen from "./Screens/Reviewscreen";
import Sellerscreen from "./Screens/Sellerscreen";
import OrderConfirmationscreen from "./Screens/OrderConfirmationscreen";
import Sandboxscreen from "./Screens/Sandboxscreen";
import NewCartscreen from "./Screens/NewCartscreen";
import NewProductsscreen from "./Screens/NewProductsscreen";
import ReportIssueNew from "./Components/ReportIssue/ReportIssueNew";
import NoList from "./Screens/NoList";
import DeliveryOptionsScreen from "./Screens/DeliveryOptionsScreenNew";
import AddressCreateScreenNew from "./Screens/AddressCreateScreenNew";
import CollectionScreen from "./Screens/CollectionScreen";
import MyOrdersScreenNew from "./Screens/MyOrdersScreenNew";
import OrderScreen from "./Screens/OrderScreen";
import SearchScreen from "./Screens/SearchScreen";
import CategoryScreenNew from "./Screens/CategoryScreenNew";

const AppRouter = () => {
  return (
    <>
      <Route path="/" component={Homescreen} exact />
      <Route path="/about-us" component={Aboutscreen} />
      <Route path="/cart/:id?" component={NewCartscreen} />
      <Route
        path="/category/:id/:name/:sid/:subName"
        component={CategoryScreenNew}
      />
      <Route path="/checkout/:addressId" component={CheckoutScreenNew} />
      <Route path="/collection/:id/:name" component={CollectionScreen} />
      <Route path="/contact-us" component={Contactscreen} />
      <Route path="/create-review/:id/:name" component={Reviewscreen} />
      <Route path="/delSlots/:addressId" component={DeliveryOptionsScreen} />
      <Route path="/help/:orderId?" component={ReportIssueNew} />
      <Route path="/login" component={Loginscreen} exact />
      <Route path="/login/verify/" component={Verifyscreen} />
      <Route path="/myorders" component={MyOrdersScreenNew} />
      <Route path="/newproducts" component={NewProductsscreen} exact />
      <Route path="/onboarding" component={Onboardingscreen} exact />
      <Route path="/order/:id" component={OrderScreen} />
      <Route path="/order/confirm/:id" component={OrderConfirmationscreen} />
      <Route path="/privacy" component={Privacyscreen} />
      <Route path="/product/:id" component={Productscreen} />
      <Route path="/profile" component={Profilescreen} />
      <Route path="/prohibited-items" component={NoList} />
      <Route path="/search" component={SearchScreen} />
      <Route path="/seller/:id" component={Sellerscreen} exact />
      <Route path="/shipping" component={Shippingscreen} exact />
      <Route
        path="/shipping/create-address"
        component={AddressCreateScreenNew}
      />
      <Route path="/shipping-policy" component={ShippingPolicyscreen} />
      <Route path="/tnc" component={Termsscreen} />
      <Route path="/test" component={Sandboxscreen} />
    </>
  );
};

export default AppRouter;
