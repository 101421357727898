import { VEG_INDICATOR_COLORS } from "../Constants/GlobalConstants";

function VegIndicator({ indicator }) {
  const indiStyle = VEG_INDICATOR_COLORS.find(
    (item) => item.indicator === indicator
  );

  return indicator === "Vegan" ? (
    <i
      className="fas fa-seedling fa-lg"
      style={{ color: indiStyle ? indiStyle.color : null }}
    ></i>
  ) : (
    <span
      className="fa-stack fa-sm"
      style={{ color: indiStyle ? indiStyle.color : null }}
    >
      <i className="far fa-square fa-stack-2x fa-sm"></i>
      <i className="fas fa-circle fa-stack-1x fa-sm"></i>
    </span>
  );
}
export default VegIndicator;
