import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Components/Loader";
import FormContainer from "../Components/FormContainer";
import { getShippingAddressList } from "../Actions/CartActions";
import ErrorHandle from "../Components/ErrorHandle";
import {
  APARTMENT_ADDRESS_CREATE_RESET,
  SHIPPING_ADDRESS_CREATE_RESET,
} from "../Constants/CartConstants";
import {
  ORDER_CREATE_RESET,
  RAZORPAY_RESET,
} from "../Constants/OrderConstants";
import scrollToTop from "../Utils/ScrollToTop";
import AddressCard from "../Components/Shipping/AddressCard";
import usePageTracking from "../CustomHooks/usePageTracking";

function Shippingscreen({ history }) {
  const dispatch = useDispatch();

  const [deliveryAddress, setDeliveryAddress] = useState(-1);

  const shippingAddressList = useSelector((state) => state.shippingAddressList);
  const { loading, addressList, error } = shippingAddressList;

  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const { login } = userVerifyOTP;

  usePageTracking("Shipping Screen");

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (login && Object.keys(login).length > 0) {
      dispatch(getShippingAddressList());
    } else {
      history.push("/login?redirect=shipping");
    }
  }, [dispatch, login]);

  useEffect(() => {
    if (addressList && addressList.length > 0) {
      if (addressList.length === 1) {
        if (addressList[0]?.isServiceable) {
          handleSelectAddress(addressList[0].id);
        }
      }
    }
  }, [addressList]);

  const handleSelectAddress = (id) => {
    setDeliveryAddress(id);
  };

  const handleAddAddress = () => {
    dispatch({ type: SHIPPING_ADDRESS_CREATE_RESET });
    dispatch({ type: APARTMENT_ADDRESS_CREATE_RESET });
    history.push("/shipping/create-address");
  };

  const handleCheckout = () => {
    if (deliveryAddress !== -1) {
      dispatch({ type: ORDER_CREATE_RESET });
      dispatch({ type: RAZORPAY_RESET });
      history.push(`/delSlots/${deliveryAddress}`);
    }
  };

  return (
    <>
      <FormContainer>
        {loading ? (
          <Loader />
        ) : error ? (
          <ErrorHandle error={error} history={history} />
        ) : addressList && addressList.length > 0 ? (
          <>
            <h3>Select a delivery address</h3>
            {addressList.map((address) => (
              <AddressCard
                address={address}
                deliveryAddress={deliveryAddress}
                handleSelectAddress={handleSelectAddress}
              />
            ))}
            <Button variant="secondary" onClick={handleAddAddress}>
              Add a new Address
            </Button>{" "}
            {"    "}
            <Button disabled={deliveryAddress === -1} onClick={handleCheckout}>
              Continue
            </Button>
          </>
        ) : (
          <>
            <h3>No address found. Please add address</h3>
            <Button variant="secondary" onClick={handleAddAddress}>
              Add a new Address
            </Button>
          </>
        )}
      </FormContainer>
      <br />
    </>
  );
}

export default Shippingscreen;
