import React from "react";
import { Col, ListGroup, Row } from "react-bootstrap";

const AmountDetails = (props) => {
  const { itemsPrice, deliveryFee, discountCoupon, cartItems } = props;
  const calculateTotalPrice = (items) =>
    items.reduce((acc, item) => acc + item.quantityPossible * item.price, 0);

  const calculateDiscount = (coupon, total) => {
    if (!coupon || !coupon.isValid) return 0;
    const discount = (total * coupon.discountPercentage) / 100;
    return discount > coupon.discountCap
      ? coupon.discountCap
      : Math.round(discount);
  };

  const totalPrice = calculateTotalPrice(cartItems);
  const discount = calculateDiscount(discountCoupon, totalPrice);
  const finalTotal = (totalPrice - discount + deliveryFee).toFixed(2);

  return (
    <div className="my-2 py-0">
      <ListGroup.Item>
        <h5 className="text-heading-secondary">Total amount</h5>
      </ListGroup.Item>

      <ListGroup.Item>
        <Row>
          <Col>Items:</Col>
          <Col>&#8377;{itemsPrice}</Col>
        </Row>
      </ListGroup.Item>

      <ListGroup.Item>
        <Row>
          <Col>Delivery Fee:</Col>
          <Col>&#8377;{deliveryFee}</Col>
        </Row>
      </ListGroup.Item>

      {discount > 0 && (
        <ListGroup.Item>
          <Row>
            <Col style={{ color: "red" }}>Discount :</Col>
            <Col style={{ color: "red" }}>- &#8377;{discount.toFixed(2)}</Col>
          </Row>
        </ListGroup.Item>
      )}
      <ListGroup.Item>
        <Row>
          <Col>Total:</Col>
          <Col>&#8377;{finalTotal}</Col>
        </Row>
      </ListGroup.Item>
    </div>
  );
};

export default AmountDetails;
