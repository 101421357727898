import {
  issues,
  orderIssues,
  productIssues,
} from "../../Constants/IssueScreenConstants";

export const createReportIssueString = (orderId) => {
  if (!orderId) {
    return "Report issue";
  }

  return `Report issue for order #${orderId}`;
};

export const createResponseArr = (isActive, amount, storeName) => {
  const optionReplace = "Replacement of this product";
  const optionDiscount = `Discount of Rs. ${amount} on the next order`;
  const optionNothing = `Nothing required, give feedback to ${storeName}`;

  return isActive
    ? [
        { type: "REPLACEMENT", value: optionReplace },
        { type: "DISCOUNT", value: optionDiscount },
        { type: "NOTHING", value: optionNothing },
      ]
    : [
        { type: "DISCOUNT", value: optionDiscount },
        { type: "NOTHING", value: optionNothing },
      ];
};

export const getIfProductActive = (selectedItem) => {
  const isSelected = selectedItem && selectedItem.productId;

  if (!isSelected) {
    return false;
  }

  if (
    isSelected &&
    selectedItem.productIsActive &&
    Number(selectedItem.productStatus) === 20
  ) {
    return true;
  }
  return false;
};

export const getItemAmount = (selectedItem) => {
  if (!selectedItem) {
    return 0;
  }
  return selectedItem.price * selectedItem.quantity;
};

export const detailValidator = (details, issue) => {
  if (issue !== issues.ORDER_NOT_DELIVERED) {
    if (details.length < 3) {
      return false;
    }
  }
  return true;
};

export const generateDetailsLabel = (issue) => {
  switch (issue) {
    case issues.ORDER_NOT_DELIVERED:
      return "Apologies for the inconvenience.";

    case issues.WRONG_ITEMS_DELIVERED:
      return "Kindly share details of the incorrect items received";

    case issues.PRODUCT_QUALITY:
      return "Kindly help us understand the issue in detail";

    case issues.PRODUCT_QUANTITY:
      return "Please let us know how much quantity you received";

    case issues.PRODUCT_PACKAGING:
      return "Kindly share details of the packaging or spillage issue";

    case issues.OTHER:
      return "Please share details about the issue";

    default:
      return "Any details you would like to tell us?";
  }
};

export const getIssueType = (issue) => {
  if (orderIssues.includes(issue)) {
    return "ORDER";
  } else if (productIssues.includes(issue)) {
    return "PRODUCT";
  } else {
    return "";
  }
};

export const createSubmitRow = (
  login,
  orderId,
  OrderDisplayId,
  selectIssue,
  selectItem,
  type,
  details,
  selectResponse
) => {
  const now = new Date();
  const ISTDateTime = now.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  return {
    TicketId: Date.now(),
    Timestamp: ISTDateTime,
    UserName: login.firstName,
    PhoneNo: login.mobile,
    OrderId: orderId,
    OrderDisplayId: OrderDisplayId,
    ItemName: selectItem.name,
    ItemPID: selectItem.productId,
    type: type,
    IssueType: selectIssue,
    IssueDetails: details,
    DiscountOnNextOrder:
      selectResponse?.type === "DISCOUNT" ? getItemAmount(selectItem) : 0,
    Replacement: selectResponse?.type === "REPLACEMENT" ? true : false,
  };
};

export const showDetailInput = (issue, item, isProductIssue) => {
  if (issue === issues.ORDER_NOT_DELIVERED) {
    return false;
  }
  if (isProductIssue && item) {
    return true;
  }
  if (!isProductIssue && issue) {
    return true;
  }
  return false;
};
