import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { filterSlotsByAddress, getIfMorningSlot } from "../Utils/SlotFunction";
import { getAddressById, getDeliveryOptions } from "../Actions/CartActions";
import { displayFlashMessage } from "../Actions/FlashMessageActions";
import DeliveryOptionCard from "../Components/DeliveryOptions/DeliveryOptionsCard";
import SlotConfirmModal from "../Components/DeliveryOptions/SlotConfirmModal";
import usePageTracking from "../CustomHooks/usePageTracking";

function DeliveryOptionsScreen({ history, match }) {
  const dispatch = useDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const {
    delAddress,
    loading: loadingAddress,
    error: errorAddress,
  } = useSelector((state) => state.deliveryAddress);

  const [slotModalData, setSlotModalData] = useState({
    isMultiSlotOrder: false,
    morningSlotTime: "",
  });

  const addressId = match.params.addressId;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const deliveryOptions = useSelector((state) => state.deliveryOptions);
  const { loading, delOptions = {}, error } = deliveryOptions;

  const onCancelHandler = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmModal = (slotOptions) => {
    const [isMorning, isMulti, morningSlotTime, onDemand] =
      getIfMorningSlot(slotOptions);
    if (isMorning && !onDemand) {
      setSlotModalData({
        isMultiSlotOrder: isMulti,
        morningSlotTime: morningSlotTime,
      });
      setShowConfirmModal(true);
      return;
    } else {
      history.push(`/checkout/${addressId}`);
    }
  };

  usePageTracking("Del Options Screen");

  useEffect(() => {
    if (Boolean(cartItems && cartItems.length > 0)) {
      dispatch(
        getDeliveryOptions({
          productList: cartItems.map((item) => {
            return { id: item.id, quantity: item.quantityPossible };
          }),
        })
      );
    } else {
      dispatch(
        displayFlashMessage({
          position: "top-center",
          delay: 5000,
          background: "danger",
          string: "No Items in Cart",
        })
      );
      history.push("/");
    }
  }, [dispatch, cartItems]);

  useEffect(() => {
    if (!delAddress || delAddress.id !== addressId) {
      dispatch(getAddressById(addressId));
    }
  }, [dispatch, addressId]);

  const filteredAddress = filterSlotsByAddress(delOptions?.options, delAddress);

  return Boolean(cartItems && cartItems.length > 0) ? (
    Boolean(filteredAddress && Object.keys(filteredAddress).length > 0) ? (
      <Row className="delOptions m-0 p-0 d-flex justify-content-evenly">
        <>
          <h3>Select a delivery option</h3>
          {filteredAddress?.map((option, index) => {
            const { deliveries } = option;

            return (
              Boolean(deliveries && deliveries.length > 0) && (
                <Col key={`column-${index}`} xs={12} md={6} lg={6} xl={6}>
                  <DeliveryOptionCard
                    index={index}
                    option={option}
                    handleConfirmModal={handleConfirmModal}
                    addressId={addressId}
                    history={history}
                    cartItems={cartItems}
                  />

                  {showConfirmModal && (
                    <SlotConfirmModal
                      showModal={showConfirmModal}
                      onPrimaryHandler={() => {
                        history.push(`/checkout/${addressId}`);
                      }}
                      onSecondaryHandler={onCancelHandler}
                      slotData={slotModalData}
                    />
                  )}
                </Col>
              )
            );
          })}
        </>
      </Row>
    ) : (
      <p>No delivery options</p>
    )
  ) : (
    <p>Cart is Empty</p>
  );
}

export default DeliveryOptionsScreen;
