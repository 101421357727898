import {
  CART_ADD_ITEM,
  CART_REMOVE_ITEM,
  CART_CLEAR_ITEMS,
  CART_ITEMS_UPDATE,
  CART_UPDATE_REQUEST,
  CART_UPDATE_SUCCESS,
  CART_UPDATE_FAIL,
  CART_UPDATE_RESET,
  CART_SAVE_SHIPPING_ADDRESS,
  CART_SAVE_PAYMENT_METHOD,
  SHIPPING_ADDRESS_LIST_SUCCESS,
  SHIPPING_ADDRESS_LIST_REQUEST,
  SHIPPING_ADDRESS_LIST_FAIL,
  SHIPPING_ADDRESS_CREATE_SUCCESS,
  SHIPPING_ADDRESS_CREATE_REQUEST,
  SHIPPING_ADDRESS_CREATE_FAIL,
  SHIPPING_ADDRESS_CREATE_RESET,
  SHIPPING_ADDRESS_DETAILS_SUCCESS,
  SHIPPING_ADDRESS_DETAILS_REQUEST,
  SHIPPING_ADDRESS_DETAILS_FAIL,
  SHIPPING_ADDRESS_DETAILS_RESET,
  CART_ADD_FAIL,
  CART_LIST_REQUEST,
  CART_LIST_SUCCESS,
  CART_LIST_FAIL,
  CART_LIST_UPDATE,
  APARTMENT_LIST_SUCCESS,
  APARTMENT_LIST_REQUEST,
  APARTMENT_LIST_FAIL,
  APARTMENT_ADDRESS_CREATE_SUCCESS,
  APARTMENT_ADDRESS_CREATE_REQUEST,
  APARTMENT_ADDRESS_CREATE_FAIL,
  APARTMENT_ADDRESS_CREATE_RESET,
  DELIVERY_OPTIONS_REQUEST,
  DELIVERY_OPTIONS_SUCCESS,
  DELIVERY_OPTIONS_FAIL,
  IS_CUSTOMER_POD_ALLOLWED_REQUEST,
  IS_CUSTOMER_POD_ALLOLWED_SUCCESS,
  IS_CUSTOMER_POD_ALLOLWED_FAIL,
} from "../Constants/CartConstants";

export const editCartReducer = (state = { cartItems: [] }, action) => {
  switch (action.type) {
    case CART_ADD_ITEM: {
      const newItem = action.payload;

      if (Boolean(state.cartItems && state.cartItems.length > 0)) {
        const check = state.cartItems.find((item) => item.id === newItem.id);
        if (check) {
          localStorage.setItem(
            "cartItemsNew1",
            JSON.stringify(
              state.cartItems.map((item) =>
                item.id === newItem.id ? newItem : item
              )
            )
          );
          return {
            ...state,
            cartItems: state.cartItems.map((item) =>
              item.id === newItem.id ? newItem : item
            ),
          };
        } else {
          localStorage.setItem(
            "cartItemsNew1",
            JSON.stringify(state.cartItems.concat(newItem))
          );
          return { ...state, cartItems: state.cartItems.concat(newItem) };
        }
      } else {
        localStorage.setItem("cartItemsNew1", JSON.stringify([newItem]));
        return { ...state, cartItems: [newItem] };
      }
    }
    case CART_ITEMS_UPDATE:
      return { ...state, cartItems: action.payload };
    case CART_ADD_FAIL:
      return { ...state, error: action.payload };
    case CART_REMOVE_ITEM: {
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.id !== action.payload),
      };
    }
    case CART_SAVE_PAYMENT_METHOD: {
      return { ...state, paymentMethod: action.payload };
    }
    case CART_CLEAR_ITEMS: {
      localStorage.removeItem("cartItemsNew1");
      return { ...state, cartItems: [] };
    }
    default:
      return state;
  }
};

export const shippingAddressListReducer = (
  state = { addressList: [] },
  action
) => {
  switch (action.type) {
    case SHIPPING_ADDRESS_LIST_REQUEST:
      return { loading: true };
    case SHIPPING_ADDRESS_LIST_SUCCESS:
      return { loading: false, addressList: action.payload };
    case SHIPPING_ADDRESS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createShippingAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case SHIPPING_ADDRESS_CREATE_REQUEST:
      return { loading: true };
    case SHIPPING_ADDRESS_CREATE_SUCCESS:
      return { loading: false, success: true };
    case SHIPPING_ADDRESS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case SHIPPING_ADDRESS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const deliveryAddressReducer = (state = { delAddress: {} }, action) => {
  switch (action.type) {
    case SHIPPING_ADDRESS_DETAILS_REQUEST:
      return { loading: true };
    case SHIPPING_ADDRESS_DETAILS_SUCCESS:
      return { loading: false, delAddress: action.payload };
    case SHIPPING_ADDRESS_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const cartItemsListReducer = (state = { slots: [] }, action) => {
  switch (action.type) {
    case CART_LIST_REQUEST:
      return { loading: true };
    case CART_LIST_SUCCESS:
      return { loading: false, success: true, slots: action.payload };
    case CART_LIST_FAIL:
      return { loading: false, error: action.payload };
    case CART_LIST_UPDATE:
      return { loading: false, slots: action.payload };
    default:
      return state;
  }
};

export const apartmentListReducer = (state = { apartments: [] }, action) => {
  switch (action.type) {
    case APARTMENT_LIST_REQUEST:
      return { loading: true, apartments: [] };
    case APARTMENT_LIST_SUCCESS:
      return { loading: false, apartments: action.payload };
    case APARTMENT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createApartmentAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case APARTMENT_ADDRESS_CREATE_REQUEST:
      return { loading: true };
    case APARTMENT_ADDRESS_CREATE_SUCCESS:
      return { loading: false, success: true };
    case APARTMENT_ADDRESS_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case APARTMENT_ADDRESS_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const deliveryOptionsReducer = (state = { delOptions: {} }, action) => {
  switch (action.type) {
    case DELIVERY_OPTIONS_REQUEST:
      return { loading: true };
    case DELIVERY_OPTIONS_SUCCESS:
      return { loading: false, delOptions: action.payload };
    case DELIVERY_OPTIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const isCustomerPodAllowedReducer = (
  state = { isCustomerPodAllowed: { isCustomerPodAllowed: true } },
  action
) => {
  switch (action.type) {
    case IS_CUSTOMER_POD_ALLOLWED_REQUEST:
      return { loading: true };
    case IS_CUSTOMER_POD_ALLOLWED_SUCCESS:
      return { loading: false, isCustomerPodAllowed: action.payload };
    case IS_CUSTOMER_POD_ALLOLWED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
