import axios from "axios";
import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  NEW_PRODUCT_LIST_REQUEST,
  NEW_PRODUCT_LIST_SUCCESS,
  NEW_PRODUCT_LIST_FAIL,
  BANNER_GET_REQUEST,
  BANNER_GET_SUCCESS,
  BANNER_GET_FAIL,
  RECOMMENDATIONS_GET_REQUEST,
  RECOMMENDATIONS_GET_SUCCESS,
  RECOMMENDATIONS_GET_FAIL,
  COLLECTION_DETAILS_REQUEST,
  COLLECTION_DETAILS_SUCCESS,
  COLLECTION_DETAILS_FAIL,
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_FAIL,
  COLLECTION_LIST_SUCCESS,
  PRODUCT_RECOMMENDATIONS_REQUEST,
  PRODUCT_RECOMMENDATIONS_SUCCESS,
  PRODUCT_RECOMMENDATIONS_FAIL,
  PREV_PRODUCTS_REQUEST,
  PREV_PRODUCTS_SUCCESS,
  PREV_PRODUCTS_FAIL,
  COLLECTION_MADE_TYPE_REQUEST,
  COLLECTION_MADE_TYPE_SUCCESS,
  COLLECTION_MADE_TYPE_FAIL,
  COLLECTION_ITEMS_BY_TYPE_REQUEST,
  COLLECTION_ITEMS_BY_TYPE_SUCCESS,
  COLLECTION_ITEMS_BY_TYPE_FAIL,
  CATEGORY_PRODUCTS_REQUEST,
  CATEGORY_PRODUCTS_SUCCESS,
  CATEGORY_PRODUCTS_FAIL,
} from "../Constants/ProductConstants";

export const getProductList =
  (page = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });
      const currentProducts = getState().productList.products;
      const { data } = await axios.get(`api/products/?pageNum=${page}`);
      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: {
          products:
            page === 1 ? data.products : [...currentProducts, ...data.products],
          paginator: data.paginator,
        },
      });
    } catch (error) {
      dispatch({ type: PRODUCT_LIST_FAIL, payload: error.response });
    }
  };

export const getSearchList =
  (keywordValue = "", page = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_LIST_REQUEST });
      const currentProducts = getState().productList.products;
      const { data } = await axios.get(
        `api/products/search/?keyword=${
          keywordValue ? keywordValue : ""
        }&pageNum=${page}`
      );
      dispatch({
        type: PRODUCT_LIST_SUCCESS,
        payload: {
          products:
            page === 1 ? data.products : [...currentProducts, ...data.products],
          paginator: data.paginator,
        },
      });
    } catch (error) {
      dispatch({ type: PRODUCT_LIST_FAIL, payload: error.response });
    }
  };

export const getProductDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_DETAILS_REQUEST });

    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;

    const config = login
      ? {
          headers: {
            "Content-type": "Application/json",
            Authorization: "Token " + login.token,
          },
        }
      : "";

    const { data } = await axios.get(`api/products/${id}/`, config);

    dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: PRODUCT_DETAILS_FAIL, payload: error.response });
  }
};

export const getCategories = () => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_LIST_REQUEST });

    const { data } = await axios.get("api/products/category-list/");
    dispatch({ type: CATEGORY_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CATEGORY_LIST_FAIL, payload: error.response });
  }
};

export const getCategoryItems = (id, sid) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_PRODUCTS_REQUEST });
    const { data } = await axios.get(`api/products/category/${id}/${sid}/`);
    dispatch({ type: CATEGORY_PRODUCTS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CATEGORY_PRODUCTS_FAIL, payload: error.response });
  }
};

export const getCategoryDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CATEGORY_DETAILS_REQUEST });
    const { data } = await axios.get(`api/products/category/${id}/details/`);
    dispatch({ type: CATEGORY_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CATEGORY_DETAILS_FAIL, payload: error.response });
  }
};

export const getNewProductList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: NEW_PRODUCT_LIST_REQUEST });
    const { data } = await axios.get(`api/products/npi/`);
    dispatch({ type: NEW_PRODUCT_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: NEW_PRODUCT_LIST_FAIL, payload: error.response });
  }
};

export const getBanners = () => async (dispatch) => {
  try {
    dispatch({ type: BANNER_GET_REQUEST });

    const { data } = await axios.get("api/products/banners/");
    dispatch({ type: BANNER_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: BANNER_GET_FAIL, payload: error.response });
  }
};

export const getRecommendationsCart = () => async (dispatch) => {
  try {
    dispatch({ type: RECOMMENDATIONS_GET_REQUEST });
    const { data } = await axios.get("api/products/cart-collection/");
    dispatch({ type: RECOMMENDATIONS_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: RECOMMENDATIONS_GET_FAIL, payload: error.response });
  }
};

export const getCollectionItems = (id) => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_DETAILS_REQUEST });
    const { data } = await axios.get(`api/products/product-collection/${id}/`);
    dispatch({ type: COLLECTION_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COLLECTION_DETAILS_FAIL, payload: error.response });
  }
};

export const getCollectionItemsByType = (type) => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_ITEMS_BY_TYPE_REQUEST });
    const { data } = await axios.get(
      `api/products/product-collection-by-type/${type}/`
    );
    dispatch({ type: COLLECTION_ITEMS_BY_TYPE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COLLECTION_ITEMS_BY_TYPE_FAIL, payload: error.response });
  }
};

export const getCurationCollections = () => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_LIST_REQUEST });
    const { data } = await axios.get(
      "api/products/product-collection-type/curation/"
    );
    dispatch({ type: COLLECTION_LIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COLLECTION_LIST_FAIL, payload: error.response });
  }
};

export const getCollectionWithWithout = () => async (dispatch) => {
  try {
    dispatch({ type: COLLECTION_MADE_TYPE_REQUEST });
    const { data } = await axios.get(
      "api/products/product-collection-made-type/"
    );
    dispatch({ type: COLLECTION_MADE_TYPE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: COLLECTION_MADE_TYPE_FAIL, payload: error.response });
  }
};

export const getProductRecommendations = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_RECOMMENDATIONS_REQUEST });
    const { data } = await axios.get(
      `api/products/product-recommendations/${id}/`
    );
    const finalData = { ...data, id: id };

    dispatch({
      type: PRODUCT_RECOMMENDATIONS_SUCCESS,
      payload: finalData,
    });
  } catch (error) {
    dispatch({ type: PRODUCT_RECOMMENDATIONS_FAIL, payload: error.response });
  }
};

export const getPrevOrderedProducts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PREV_PRODUCTS_REQUEST });

    const { userVerifyOTP } = getState();
    const { login } = userVerifyOTP;

    const config = login
      ? {
          headers: {
            "Content-type": "Application/json",
            Authorization: "Token " + login.token,
          },
        }
      : "";

    const { data } = await axios.get(
      "api/products/prev-ordered-products/",
      config
    );
    dispatch({
      type: PREV_PRODUCTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: PREV_PRODUCTS_FAIL,
      payload: error.response,
    });
  }
};
