import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import FormContainer from "../FormContainer";
import {
  createReportIssueString,
  detailValidator,
  generateDetailsLabel,
  getIssueType,
  showDetailInput,
} from "./utils";
import { Link } from "react-router-dom";
import IssueSelect from "./IssueSelect";
import ItemSelect from "./ItemSelect";
import Button from "react-bootstrap/Button";
import ResponseSelect from "./ResponseSelect";
import Loader from "../Loader";
import TicketContainer from "./TicketContainer";
import DetailsInput from "./DetailsInput";
import { getOrderById } from "../../Actions/OrderActions";
import { CUSTOMER_ISSUE_WRITE_RESET } from "../../Constants/ReviewConstants";
import { writeCustomerIssues } from "../../Actions/ReviewActions";
import ImageUpload from "../ImageUpload";

const ReportIssueNew = ({ match, history }) => {
  const [selectIssue, setSelectIssue] = useState("");
  const [selectItem, setSelectItem] = useState("");
  const [details, setDetails] = useState("");
  const [submitDetails, setSubmitDetails] = useState(false);
  const [validDetails, setValidDetails] = useState(false);
  const [selectResponse, setSelectResponse] = useState({ type: "", value: "" });
  const [ticketNumber, setTicketNumber] = useState(-1);
  const [isSubmit, setIsSubmit] = useState(false);
  const [validated, setValidated] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [images, setImages] = useState([]);
  const imageUploadRef = useRef(null);

  const dispatch = useDispatch();
  const {
    order,
    loading,
    error: orderDetailsError,
  } = useSelector((state) => state.orderDetails);
  const userVerifyOTP = useSelector((state) => state.userVerifyOTP);
  const customerIssueWrite = useSelector((state) => state.writeCustomerIssue);

  const orderId = match?.params?.orderId;

  const { loading: issueLoading, success, error } = customerIssueWrite;
  const { login } = userVerifyOTP;

  const reportIssueString = createReportIssueString(orderId);
  const detailsLabel = generateDetailsLabel(selectIssue);
  const issueType = getIssueType(selectIssue);
  const isProductIssue = issueType === "PRODUCT" ? true : false;
  const submitButtonCondition =
    (!isProductIssue && selectIssue) ||
    (isProductIssue && selectResponse.value);

  const showDetailsInput = showDetailInput(
    selectIssue,
    selectItem,
    isProductIssue
  );
  const responseCondition = validDetails && submitDetails;

  useEffect(() => {
    dispatch({ type: CUSTOMER_ISSUE_WRITE_RESET });
    if (!login) {
      history.push("/login?redirect=help");
    }
  }, [history, login]);

  useEffect(() => {
    if (success) {
      setIsSubmit(true);
    }
  }, [success]);

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderById(orderId));
    }
  }, [match.params, dispatch]);

  const validateDetails = () => {
    const isValidDetail = detailValidator(details, selectIssue);
    setValidDetails(isValidDetail);
    setSubmitError(isValidDetail ? "" : "Please add valid details");
    return isValidDetail;
  };

  const onDetailSubmitHandler = () => {
    if (validateDetails()) setSubmitDetails(true);
  };

  const onDetailsBlurHandler = validateDetails;

  const handleReset = () => {
    setIsSubmit(false);
    setSubmitError("");
    dispatch({ type: CUSTOMER_ISSUE_WRITE_RESET });
    history.push("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateDetails()) return;

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropogation();
      return;
    }
    const postData = new FormData();
    postData.append("type", selectIssue);
    postData.append("order", orderId ? orderId : "");
    postData.append("orderItem", selectItem?.id ? selectItem.id : "");
    postData.append("description", details);
    postData.append("isDraft", true);
    postData.append("severity", 1);
    postData.append("isInternal", false);
    postData.append("quantity", selectItem ? 1 : 0);
    postData.append("isInternal", false);
    postData.append("resolutionType", selectResponse?.type);

    for (let i = 0; i < images.length; i++) {
      postData.append("images", images[i]);
    }
    setValidated(true);
    setSubmitError("");
    const res = await dispatch(writeCustomerIssues(postData));
    setTicketNumber(res?.id);
  };

  if (issueLoading) {
    return (
      <FormContainer>
        <Loader />
      </FormContainer>
    );
  }

  if (isSubmit && ticketNumber > 0) {
    return (
      <TicketContainer
        ticketNumber={ticketNumber}
        selectIssue={selectIssue}
        selectResponse={selectResponse}
        selectItem={selectItem}
        handleReset={handleReset}
        phone={login.mobile}
      />
    );
  }

  return (
    <FormContainer>
      <Form noValidate onSubmit={handleSubmit} validated={validated}>
        <h5 className="text-heading-secondary">{reportIssueString}</h5>
        {!orderId && (
          <p>
            For order/item related issue please go to{" "}
            <Link to="/myorders">My Orders page</Link> and click on report issue
            for the specific order
          </p>
        )}
        <IssueSelect setSelectIssue={setSelectIssue} orderId={orderId} />
        <ItemSelect
          selectIssue={selectIssue}
          setSelectItem={setSelectItem}
          isProductIssue={isProductIssue}
          order={order}
        />
        {showDetailsInput && (
          <DetailsInput
            detailsLabel={detailsLabel}
            setDetails={setDetails}
            onDetailsBlurHandler={onDetailsBlurHandler}
            onDetailSubmitHandler={onDetailSubmitHandler}
            isProductIssue={isProductIssue}
          />
        )}
        {showDetailsInput && (
          <div className="d-flex flex-column mb-3">
            <label>Image(s) of issue:</label>
            <ImageUpload
              setSelectedFiles={setImages}
              imageUploadRef={imageUploadRef}
              multiple={true}
            />
          </div>
        )}
        {responseCondition && (
          <ResponseSelect
            selectItem={selectItem}
            setSelectResponse={setSelectResponse}
            isProductIssue={isProductIssue}
          />
        )}

        {submitError && (
          <p style={{ color: "red", marginTop: 5 }}>{submitError}</p>
        )}
        {submitButtonCondition && (
          <Button type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        )}
      </Form>
    </FormContainer>
  );
};

export default ReportIssueNew;
