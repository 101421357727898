import React from "react";
import PlusMinusButton from "../PlusMinusButton";
import Button from "react-bootstrap/Button";
import { trackEvent } from "../../Config/amplitude";

const AddButton = ({
  cartQty,
  product,
  changeQty,
  removeFromCart,
  trackingData,
}) => {
  const { eventHeading = "Added Product", eventProps = {} } = trackingData;
  const renderPlusMinusButton = () => (
    <span style={{ float: "right" }}>
      <PlusMinusButton
        key={product.id}
        qty={cartQty}
        changeQty={changeQty}
        removeFromCart={removeFromCart}
      />
    </span>
  );

  const renderAddButton = () => (
    <Button
      variant="outline-warning"
      onClick={(event) => {
        changeQty("+", event);
        trackEvent(eventHeading, { pid: product?.id, ...eventProps });
      }}
      size="sm"
      className="add-button add-button-colors"
    >
      ADD
    </Button>
  );

  const renderStockStatus = () => (
    <h6 style={{ float: "right" }}>
      {product.quantityAvailable > 0 ? "Sold Out" : "Out of Stock"}
    </h6>
  );

  if (cartQty > 0) {
    return renderPlusMinusButton();
  } else if (product.showStock) {
    return product.quantityAvailable > 0
      ? renderAddButton()
      : renderStockStatus();
  } else {
    return renderStockStatus();
  }
};

export default AddButton;
