import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import Carousel from "react-bootstrap/Carousel";
import { useHistory } from "react-router-dom";
import ReportModal from "../ReportModal";
import { checkToday } from "../../Utils/DateTimeFunctions";
import { CUT_OFF_RATING } from "../../Utils/reviewsHelpers";

const DeliveryBadge = ({ date }) => {
  if (date && checkToday(date) === "today") {
    return (
      <h5>
        <Badge className="product-deltime-tag" bg="danger">
          Get it Today
        </Badge>
      </h5>
    );
  }
  return null;
};

const RatingBadge = ({ rating }) => (
  <h5>
    <Badge pill bg="secondary" text="primary" className="product-rating-badge">
      <span className="p-0 m-0">
        {rating.toFixed(1)} <i className="fas fa-star"></i>
      </span>
    </Badge>
  </h5>
);

const SpecialityTagBadge = ({ tag }) => (
  <h5>
    <Badge pill className="product-speciality-tag" bg="light" text="primary">
      {tag}
    </Badge>
  </h5>
);

const createImagesArr = (image1, onClick1, image2, onClick2, showReport) => {
  const arr = [];

  if (image1) {
    arr.push({ image: image1, onClick: onClick1 });
  }
  if (image2) {
    if (showReport) {
      arr.push({ image: image2, onClick: onClick2 });
    } else {
      arr.push({ image: image2, onClick: onClick1 });
    }
  }

  return arr;
};

const CardImage = ({ product }) => {
  const {
    id,
    earliestDeliverySlot,
    rating,
    specialityTag,
    listImage,
    listImage2 = "",
    testReportDocument = "",
    testReportImage = "",
    name,
  } = product;

  const history = useHistory();
  const [ispdfShow, setPdfShow] = useState(false);

  const showReport = !!testReportDocument || !!testReportImage;

  const handlepdfClose = () => setPdfShow(false);

  const handlepdfShow = () => {
    if (showReport) {
      setPdfShow(true);
    }
  };

  const onMainImageClick = () => {
    history.push(`/product/${id}`);
  };

  const images = createImagesArr(
    listImage,
    onMainImageClick,
    listImage2,
    handlepdfShow,
    showReport
  );

  const showRatingBatch = rating && rating > CUT_OFF_RATING;

  return (
    <div className="m-0 p-0 product-listing-image" style={{ borderRadius: 16 }}>
      <DeliveryBadge date={earliestDeliverySlot?.date} />
      <Carousel
        indicators={images.length > 1 ? true : false}
        controls={false}
        interval={3000}
        style={{ borderRadius: 16 }}
      >
        {images.map((item, index) => {
          return (
            <Carousel.Item key={index}>
              <div onClick={item.onClick}>
                <Card.Img
                  src={item?.image}
                  alt="Product"
                  style={{ borderRadius: 16 }}
                />
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
      {showRatingBatch && <RatingBadge rating={rating} />}
      {specialityTag && <SpecialityTagBadge tag={specialityTag} />}
      {ispdfShow && (
        <ReportModal
          ispdfShow={ispdfShow}
          handlepdfClose={handlepdfClose}
          pdfUrl={testReportDocument}
          imageUrl={testReportImage}
          productName={name}
        />
      )}
    </div>
  );
};

export default CardImage;
