import {
  PRODUCT_LIST_REQUEST,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_SUCCESS,
  PRODUCT_DETAILS_FAIL,
  CATEGORY_LIST_REQUEST,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_LIST_FAIL,
  CATEGORY_DETAILS_REQUEST,
  CATEGORY_DETAILS_SUCCESS,
  CATEGORY_DETAILS_FAIL,
  NEW_PRODUCT_LIST_REQUEST,
  NEW_PRODUCT_LIST_SUCCESS,
  NEW_PRODUCT_LIST_FAIL,
  BANNER_GET_REQUEST,
  BANNER_GET_SUCCESS,
  BANNER_GET_FAIL,
  RECOMMENDATIONS_GET_REQUEST,
  RECOMMENDATIONS_GET_SUCCESS,
  RECOMMENDATIONS_GET_FAIL,
  COLLECTION_DETAILS_REQUEST,
  COLLECTION_DETAILS_SUCCESS,
  COLLECTION_DETAILS_FAIL,
  COLLECTION_LIST_REQUEST,
  COLLECTION_LIST_SUCCESS,
  COLLECTION_LIST_FAIL,
  PRODUCT_RECOMMENDATIONS_REQUEST,
  PRODUCT_RECOMMENDATIONS_FAIL,
  PRODUCT_RECOMMENDATIONS_SUCCESS,
  PRODUCT_LIST_RESET,
  PREV_PRODUCTS_REQUEST,
  PREV_PRODUCTS_SUCCESS,
  PREV_PRODUCTS_FAIL,
  COLLECTION_MADE_TYPE_REQUEST,
  COLLECTION_MADE_TYPE_SUCCESS,
  COLLECTION_MADE_TYPE_FAIL,
  COLLECTION_ITEMS_BY_TYPE_REQUEST,
  COLLECTION_ITEMS_BY_TYPE_FAIL,
  COLLECTION_ITEMS_BY_TYPE_SUCCESS,
  CATEGORY_PRODUCTS_REQUEST,
  CATEGORY_PRODUCTS_SUCCESS,
  CATEGORY_PRODUCTS_FAIL,
} from "../Constants/ProductConstants";

export const productListReducer = (
  state = { products: [], pagination: {} },
  action
) => {
  switch (action.type) {
    case PRODUCT_LIST_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        products: action.payload.products,
        pagination: action.payload.paginator,
      };
    case PRODUCT_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case PRODUCT_LIST_RESET:
      return {
        state: { products: [], pagination: {} },
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const productDetailsReducer = (
  state = { product: { seller: {} } },
  action
) => {
  switch (action.type) {
    case PRODUCT_DETAILS_REQUEST:
      return { ...state, loading: true };
    case PRODUCT_DETAILS_SUCCESS:
      return { loading: false, product: action.payload };
    case PRODUCT_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryListReducer = (state = { categories: [] }, action) => {
  switch (action.type) {
    case CATEGORY_LIST_REQUEST:
      return { loading: true };
    case CATEGORY_LIST_SUCCESS:
      return { loading: false, categories: action.payload };
    case CATEGORY_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryDetailsReducer = (
  state = { categoryData: {} },
  action
) => {
  switch (action.type) {
    case CATEGORY_DETAILS_REQUEST:
      return { ...state, loading: true };
    case CATEGORY_DETAILS_SUCCESS:
      return { loading: false, categoryData: action.payload };
    case CATEGORY_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const categoryProductsReducer = (
  state = { categoryProducts: {} },
  action
) => {
  switch (action.type) {
    case CATEGORY_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case CATEGORY_PRODUCTS_SUCCESS:
      return { loading: false, categoryProducts: action.payload };
    case CATEGORY_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newProductListReducer = (state = { newProducts: [] }, action) => {
  switch (action.type) {
    case NEW_PRODUCT_LIST_REQUEST:
      return { loading: true, newProducts: [] };
    case NEW_PRODUCT_LIST_SUCCESS:
      return { loading: false, newProducts: action.payload };
    case NEW_PRODUCT_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getBannerReducer = (state = { banners: [] }, action) => {
  switch (action.type) {
    case BANNER_GET_REQUEST:
      return { loading: true };
    case BANNER_GET_SUCCESS:
      return { loading: false, banners: action.payload };
    case BANNER_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getRecommendationsReducer = (state = { items: [] }, action) => {
  switch (action.type) {
    case RECOMMENDATIONS_GET_REQUEST:
      return { loading: true };
    case RECOMMENDATIONS_GET_SUCCESS:
      return { loading: false, items: action.payload };
    case RECOMMENDATIONS_GET_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const collectionDetailsReducer = (
  state = { collectionData: {} },
  action
) => {
  switch (action.type) {
    case COLLECTION_DETAILS_REQUEST:
      return { ...state, loading: true };
    case COLLECTION_DETAILS_SUCCESS:
      return { loading: false, collectionData: action.payload };
    case COLLECTION_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const collectionListReducer = (state = { collections: [] }, action) => {
  switch (action.type) {
    case COLLECTION_LIST_REQUEST:
      return { loading: true };
    case COLLECTION_LIST_SUCCESS:
      return { loading: false, collections: action.payload };
    case COLLECTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getProductRecommendationsReducer = (
  state = { items: [] },
  action
) => {
  switch (action.type) {
    case PRODUCT_RECOMMENDATIONS_REQUEST:
      return { loading: true };
    case PRODUCT_RECOMMENDATIONS_SUCCESS:
      return { loading: false, items: action.payload };
    case PRODUCT_RECOMMENDATIONS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getPrevProductReducer = (state = { prevProducts: [] }, action) => {
  switch (action.type) {
    case PREV_PRODUCTS_REQUEST:
      return { loading: true };
    case PREV_PRODUCTS_SUCCESS:
      return { loading: false, prevProducts: action.payload };
    case PREV_PRODUCTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const collectionMadeTypeReducer = (
  state = { collections: {} },
  action
) => {
  switch (action.type) {
    case COLLECTION_MADE_TYPE_REQUEST:
      return { loading: true };
    case COLLECTION_MADE_TYPE_SUCCESS:
      return { loading: false, collections: action.payload };
    case COLLECTION_MADE_TYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const collectionItemByTypeReducer = (
  state = { collectionData: {} },
  action
) => {
  switch (action.type) {
    case COLLECTION_ITEMS_BY_TYPE_REQUEST:
      return { loading: true };
    case COLLECTION_ITEMS_BY_TYPE_SUCCESS:
      return { loading: false, collectionData: action.payload };
    case COLLECTION_ITEMS_BY_TYPE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
