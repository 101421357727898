import React from "react";
import { ListGroup, Badge } from "react-bootstrap";
import VegIndicator from "../VegIndicator";
import { Link } from "react-router-dom";

const ProductMainDetails = (props) => {
  const { product } = props;
  return (
    <div>
      <ListGroup.Item
        style={{
          fontSize: "1.2rem",
          border: 0,
        }}
      >
        <div className="d-flex justify-content-between pb-0">
          <p className="text-heading-secondary p-0 m-0">{product.name}</p>
          <Badge style={{ float: "right" }} bg="secondary">
            <VegIndicator indicator={product.vegIndicator} />
          </Badge>
        </div>

        {product.specialityTag && (
          <div>
            <p className="p-0 m-0">
              <Badge bg="warning" className="h-fit">
                {product.specialityTag}
              </Badge>
            </p>
          </div>
        )}
        <div className="p-0 m-0">
          {/* <Link
            to={
              product.isNPI ? "/newproducts" : `/seller/${product.seller.id}/`
            }
            className="text-decoration-none text-body-bold"
            style={{ fontSize: "1rem" }}
          >
            {product.isNPI ? "New Product" : product.seller.storeName}
          </Link> */}
        </div>
      </ListGroup.Item>
    </div>
  );
};

export default ProductMainDetails;
