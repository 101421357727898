import React, { useEffect, useMemo } from "react";
import { Row } from "react-bootstrap";
import "../../Styles/collections.css";
import { useDispatch, useSelector } from "react-redux";
import { getCurationCollections } from "../../Actions/ProductActions";
import CollectionTile from "./CollectionTile";
import CollectionTileSkeleton from "./CollectionTileSkeleton";

const Collections = () => {
  const dispatch = useDispatch();
  const { collections, loading } = useSelector((state) => state.collectionList);
  const startIndex = 5;
  useEffect(() => {
    dispatch(getCurationCollections());
  }, [dispatch]);

  const finalCollection = useMemo(() => {
    return collections ? [...collections, ...collections, ...collections] : [];
  }, [collections]);

  const rearrangeCollection = useMemo(() => {
    return finalCollection
      ? [
          ...finalCollection.slice(startIndex),
          ...finalCollection.slice(0, startIndex),
        ]
      : [];
  }, [finalCollection, startIndex]);

  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  if (loading) {
    return <CollectionTileSkeleton />;
  }

  return (
    <div
      className={`mt-4 ${width < 600 ? "stretch-component-full" : ""} `}
      style={{
        background: "#641226",
        padding: 20,
        paddingTop: 10,
        paddingBottom: 10,
      }}
    >
      <h4 style={{ color: "#fffdd0" }} className="text-heading-primary">
        Explore Curations
      </h4>
      <p
        style={{
          color: "#fffdd0",
          fontWeight: 600,
          padding: 0,
          margin: 0,
          fontSize: "1rem",
        }}
      >
        Carefully crafted collections for you
      </p>

      <Row
        className="mt-3 scroll-container "
        style={{
          display: "grid",
          gridAutoColumns: "minmax(1fr,auto)",
          gridAutoFlow: "column",
          overflowX: "scroll",
          scrollbarWidth: 0,
          marginTop: 20,
        }}
      >
        <div
          className="infinite-scroll-animation infinite-scroll-content"
          style={{ gap: 30 }}
        >
          {finalCollection?.map((item, index) => {
            return <CollectionTile key={item.id} item={item} />;
          })}
        </div>
      </Row>
      {width < 600 && (
        <Row
          className="scroll-container"
          style={{
            display: "grid",
            gap: 30,
            gridAutoColumns: "minmax(1fr,auto)",
            gridAutoFlow: "column",
            overflowX: "scroll",
            marginTop: 20,
            translate: "",
          }}
        >
          <div
            className=" infinite-scroll-content infinite-scroll-skewed-animation"
            style={{ gap: 30 }}
          >
            {" "}
            {rearrangeCollection?.map((item, index) => {
              return <CollectionTile key={item.id} item={item} />;
            })}
          </div>
        </Row>
      )}
    </div>
  );
};

export default Collections;
