import { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../Actions/ProductActions";
import CategoryCard from "./CategoryCard";
import { analytics } from "../../Config/firebase";
import { logEvent } from "firebase/analytics";
import { homeEvents } from "../../Constants/AnalyticsEvents";

function Category({ history }) {
  const dispatch = useDispatch();
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  const applyCategory = (id, name) => {
    history.push(`/category/${id}/${name}/0/All`);
    logEvent(analytics, homeEvents.CLICKED_CATEGORY_TILE, {
      id: id,
      name: name,
    });
  };

  const categoryList = useSelector((state) => state.categoryList);
  const { categories, loading } = categoryList;

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const placeHolderItems = [...Array(12)];

  const placeHolderCards = placeHolderItems.map((_, index) => (
    <Col key={`category-card-placeholder-${index}`} style={{ padding: 5 }}>
      <CategoryCard screenWidth={width} loading={true} />
    </Col>
  ));

  const physicalCategoryCards = categories?.map((cat) => {
    const onClickHandler = () => applyCategory(cat.id, cat.name);
    return (
      <Col key={cat.id} style={{ padding: 5 }}>
        <CategoryCard
          onClickHandler={onClickHandler}
          image={cat.image}
          name={cat.name}
          screenWidth={width}
        />
      </Col>
    );
  });

  const categoryCards = loading ? placeHolderCards : physicalCategoryCards;

  if (width < 1000) {
    return (
      <Row
        xs={4}
        sm={6}
        md={6}
        lg={true}
        className="Categories p-0 d-flex mx-1 mt-2"
      >
        {categoryCards}
      </Row>
    );
  }

  return <div className="d-flex align-items-start mt-4">{categoryCards}</div>;
}

export default Category;
