import React from "react";
import { Row, Col, ListGroup, Card } from "react-bootstrap";
import ProductActionButton from "./ProductActionButton";

const PriceDetails = (props) => {
  const { product, cartQty, handleQuantityChange, removeFromCartHandler } =
    props;
  return (
    <Card className="my-3 p-3">
      <ListGroup variant="flush">
        <ListGroup.Item>
          <Row>
            <Col>Price</Col>
            <Col>&#8377;{product.price}</Col>
          </Row>
        </ListGroup.Item>

        <ListGroup.Item>
          <Row>
            <Col>Status</Col>
            <Col>
              {product.quantityAvailable > 0
                ? "In stock"
                : product.showStock
                  ? "Sold Out"
                  : "Out of Stock"}
            </Col>
          </Row>
        </ListGroup.Item>
        {product.quantityAvailable ? (
          <ListGroup.Item>
            <Row>
              <ProductActionButton
                product={product}
                cartQty={cartQty}
                changeQty={handleQuantityChange}
                removeFromCart={removeFromCartHandler}
              />
            </Row>
          </ListGroup.Item>
        ) : null}
      </ListGroup>
    </Card>
  );
};

export default PriceDetails;
