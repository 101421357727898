export const calculateTotalCheckoutAmount = (cartItems, discountCoupon) => {
  if (!cartItems) {
    return 0;
  }

  // Calculate the subtotal
  const subtotal = cartItems.reduce(
    (acc, item) => acc + item.quantityPossible * item.price,
    0
  );

  if (!discountCoupon) {
    return subtotal;
  }

  // Calculate the discount
  let discount = Math.round(
    (subtotal * discountCoupon.discountPercentage) / 100
  );

  // Apply the discount cap
  discount =
    discount > discountCoupon.discountCap
      ? discountCoupon.discountCap
      : discount;

  // Calculate the total amount after applying discount
  return subtotal - discount;
};

export const createSlotData = (selectedOptions, cartItems) => {
  const slotData = [];
  selectedOptions?.slots?.forEach((item) => {
    const { slot, products } = item;
    const productDetails = products.map((product) => {
      const cartItem = cartItems.find((item) => item.id === product.id);
      return {
        id: product.id,
        quantityPossible: cartItem ? cartItem.quantityPossible : 0,
      };
    });

    slotData.push({ id: slot.id, products: productDetails });
  });

  return { slots: slotData };
};

export const getTotalPrice = (itemsPrice, deliveryFee, taxPrice) => {
  return (Number(itemsPrice) + Number(deliveryFee) + Number(taxPrice)).toFixed(
    2
  );
};

export const getTempOrder = (
  discountCoupon,
  slotsData,
  addressId,
  deliveryFee,
  method
) => {
  if (
    Boolean(discountCoupon && Object.keys(discountCoupon).length > 0) &&
    discountCoupon.isValid
  ) {
    return {
      selectedOption: slotsData,
      address: Number(addressId),
      deliveryFee: Number(deliveryFee),
      paymentMethod: method,
      discountCode: discountCoupon.discountCode,
    };
  } else {
    return {
      selectedOption: slotsData,
      address: Number(addressId),
      deliveryFee: Number(deliveryFee),
      paymentMethod: method,
    };
  }
};

export const getTaxedPrice = (GST_PERCENTAGE, itemsPrice) => {
  return Number(GST_PERCENTAGE * itemsPrice).toFixed(2);
};

export const getItemsPrice = (cartItems) => {
  return cartItems
    .reduce((acc, item) => acc + item.price * item.quantityPossible, 0)
    .toFixed(2);
};

export const getSlotCount = (selectedSlotDetails) => {
  if (selectedSlotDetails?.slots?.length) {
    return selectedSlotDetails?.slots?.length;
  }
  return 0;
};
