import { useEffect, useState, useRef } from "react";
import { Image, Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductDetails,
  getProductRecommendations,
} from "../Actions/ProductActions";
import { displayFlashMessage } from "../Actions/FlashMessageActions";
import ErrorHandle from "../Components/ErrorHandle";
import Loader from "../Components/Loader";
import { addToCart, removeFromCart } from "../Actions/CartActions";
import scrollToTop from "../Utils/ScrollToTop";
import ReportModal from "../Components/ReportModal";
import ProductDetails from "../Components/ProductScreen/ProductDetails";
import ReviewsModal from "../Components/ProductScreen/ReviewsModal";
import PriceDetails from "../Components/ProductScreen/PriceDetails";
import { getProductFilter } from "../Utils/productHelpers";
import { filterSoldOut } from "../Utils/cartHelpers";
import AllReviews from "../Components/ProductScreen/AllReviews";
import useDocumentTitle from "../CustomHooks/useDocumentTitle";
import usePageTracking from "../CustomHooks/usePageTracking";

function Productscreen({ match, history }) {
  const dispatch = useDispatch();
  const productDetails = useSelector((state) => state.productDetails);
  const { items: productRecco = [] } = useSelector(
    (state) => state.productRecommendations
  );
  const { product, loading, error } = productDetails;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const [cartQty, setCartQty] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [imageFilter, setImageFilter] = useState("");
  const [ispdfShow, setPdfShow] = useState(false);
  const [recItems, setRecItems] = useState([]);

  const reviewsRef = useRef(null);

  const scrollToReviews = () => {
    reviewsRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    dispatch(getProductDetails(match.params.id));
    dispatch(getProductRecommendations(match?.params?.id));
  }, [dispatch, match.params.id]);

  useEffect(() => {
    if (product) {
      const filter = getProductFilter(
        product.showStock,
        product.quantityAvailable
      );
      setImageFilter(filter);
    }
  }, [product]);

  useDocumentTitle(`Homble | ${product?.name}`);
  usePageTracking("Product Screen");

  useEffect(() => {
    if (
      Boolean(product && Object.keys(product).length !== 0) &&
      Boolean(cartItems?.length > 0)
    ) {
      const tempItem = cartItems.find((item) => item.id === product.id);
      setCartQty(tempItem ? tempItem.quantityPossible : 0);
    }
  }, [cartItems, product]);

  useEffect(() => {
    const items = filterSoldOut(productRecco?.recommendations);
    setRecItems(items);
  }, [cartItems, productRecco]);

  const showReport =
    !!product?.testReportDocument || !!product?.testReportImage;

  const handleClose = () => setShowModal(false);
  const handlePdfClose = () => setPdfShow(false);
  const handlePdfShow = () => setPdfShow(true);

  const handleQuantityChange = (sign) => {
    if (sign === "+" && !(product.quantityAvailable > cartQty)) {
      dispatch(
        displayFlashMessage({
          string: "Sorry! No more stock",
        })
      );
      scrollToTop();
    } else {
      dispatch(addToCart(product.id, sign === "+" ? cartQty + 1 : cartQty - 1));
    }
  };

  const removeFromCartHandler = () => {
    dispatch(removeFromCart(product.id));
    setCartQty(0);
  };

  const handleReview = (itemId, itemName) => {
    const encodedItemName = encodeURIComponent(itemName);
    const path = `/create-review/${itemId}/${encodedItemName}`;
    history.push(path);
  };
  const handleBack = () => {
    history.go(-1);
  };

  // const showReviews = () => {
  //   setShowModal(true);
  // };

  if (loading) return <Loader />;
  if (error) return <ErrorHandle history={history} error={error} />;

  if (!product) {
    return null;
  }

  return (
    <div>
      <Button variant="secondary" onClick={handleBack}>
        Go Back
      </Button>
      <Row key={product.id} className="mt-2">
        <Col md={5}>
          <Image
            src={product.detailImage}
            alt={product.name}
            fluid
            style={{
              filter: imageFilter ? imageFilter : null,
              borderRadius: 10,
            }}
          />
        </Col>

        <Col md={4} className="mt-2 mt-sm-0">
          <ProductDetails
            product={product}
            recommendations={recItems}
            showReport={showReport}
            handlepdfShow={handlePdfShow}
            handleReview={handleReview}
            cartQty={cartQty}
            handleQuantityChange={handleQuantityChange}
            removeFromCartHandler={removeFromCartHandler}
            scrollToReviews={scrollToReviews}
          />
        </Col>

        <Col md={3}>
          <PriceDetails
            product={product}
            cartQty={cartQty}
            handleQuantityChange={handleQuantityChange}
            removeFromCartHandler={removeFromCartHandler}
          />
        </Col>
        <Col md={5}></Col>

        <Col md={4} ref={reviewsRef}>
          <AllReviews product={product} />
        </Col>
        {showModal && (
          <ReviewsModal
            showModal={showModal}
            handleClose={handleClose}
            product={product}
          />
        )}
        {ispdfShow && (
          <ReportModal
            ispdfShow={ispdfShow}
            handlepdfClose={handlePdfClose}
            pdfUrl={product?.testReportDocument}
            imageUrl={product?.testReportImage}
            productName={product?.name}
          />
        )}
      </Row>
    </div>
  );
}

export default Productscreen;
