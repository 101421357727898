import React from "react";

const SubCategoryTile = ({ item, onSubcategoryClickHandler, selectedSid }) => {
  const isSelected = selectedSid === item?.id;

  return (
    <div
      className={`subcategory-tile d-flex flex-column justify-content-center align-items-center py-2 ${isSelected ? "selected-item" : ""}`}
      style={{ cursor: "pointer" }}
      onClick={() => {
        onSubcategoryClickHandler(item?.id, item?.name);
      }}
    >
      <div
        style={{ width: 50 }}
        className={`${isSelected ? "selected-background " : ""}`}
      >
        <img
          src={item?.image}
          alt={item?.name}
          style={{ width: "100%", height: "100%", borderRadius: 10 }}
        />
        <p
          style={{
            fontWeight: isSelected ? 600 : 500,
            color: isSelected ? "black" : "#616161",
            fontSize: "0.7rem",
            textAlign: "center",
            lineHeight: 1.1,
          }}
          className="p-0 m-0 pt-1"
        >
          {item?.name}
        </p>
      </div>
    </div>
  );
};

export default SubCategoryTile;
