import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import { addToCart, removeFromCart } from "../../Actions/CartActions";
import PortionSize from "../PortionSize";
import {
  LISTING_PRODUCT_CHAR,
  LISTING_SELLER_CHAR,
} from "../../Constants/GlobalConstants";
import { sliceToSize } from "../../Utils/StringFunctions";
import CardImage from "./CardImage";
import AddButton from "./AddButton";
import RecContainer from "../Recommendations/RecContainer";
import { getProductRecommendations } from "../../Actions/ProductActions";
import { filterSoldOut } from "../../Utils/cartHelpers";
import { Badge } from "react-bootstrap";

function Product(props) {
  const { product, setShowToast, screen = "" } = props;
  const dispatch = useDispatch();
  const [showRecContainer, setShowRecContainer] = useState(null);

  const cart = useSelector((state) => state.cart);
  const { items: productRecco = [] } = useSelector(
    (state) => state.productRecommendations
  );

  const { cartItems } = cart;

  const [cartQty, setCartQty] = useState(0);
  const [recItems, setRecItems] = useState([]);

  const imageFilter = product.showStock
    ? product.quantityAvailable === 0
      ? "sepia(100%)"
      : null
    : "grayscale(70%)";

  useEffect(() => {
    if (cartItems && cartItems.length > 0) {
      const tempItem = cartItems.find((item) => item.id === product.id);
      setCartQty(tempItem ? tempItem.quantityPossible : 0);
    }
  }, [cartItems, product.id]);

  const changeQty = (sign) => {
    if (sign === "+" && !(product.quantityAvailable > cartQty)) {
      setShowToast(true);
    } else {
      dispatch(addToCart(product.id, sign === "+" ? cartQty + 1 : cartQty - 1));
      setCartQty(sign === "+" ? cartQty + 1 : cartQty - 1);

      if (sign === "+") {
        setShowRecContainer(product.id);
        dispatch(getProductRecommendations(product?.id));
      }
    }
  };

  const removeItemFromCart = () => {
    dispatch(removeFromCart(product.id));
    setCartQty(0);
    setShowRecContainer(null);
  };

  useEffect(() => {
    if (!productRecco?.recommendations || productRecco?.id !== product.id) {
      return;
    }
    const items = filterSoldOut(productRecco?.recommendations);
    setRecItems(items);
  }, [productRecco]);

  const PreorderTagBadge = () => (
    <h5>
      <Badge className="preorder-tag" bg="success">
        Preorder
      </Badge>
    </h5>
  );

  const showPreorderTag =
    product?.canSellBeforeStocking && !product?.isStockInHand;

  return (
    <Card
      className="rounded mb-2 mx-0 p-2"
      style={{ filter: imageFilter ? imageFilter : null, overflow: "hidden" }}
    >
      {product && <CardImage product={product} />}
      <Row className="d-flex flex-row justify-content-between align-items-center g-0 p-1 m-0">
        <Link
          to={`/product/${product.id}`}
          style={{ width: "85%" }}
          className="text-decoration-none my-auto"
        >
          <Card.Title as="h6" className="m-0 p-0">
            <strong style={{ color: "#313131", fontSize: "1.1rem" }}>
              {sliceToSize(product.name, LISTING_PRODUCT_CHAR)}
            </strong>{" "}
            <span
              style={{ fontSize: "0.8rem", color: "#313131" }}
              className="m-0 p-0"
            >
              ({<PortionSize packageSize={product.packageSize} />}{" "}
              {product.measurementUnit})
            </span>
          </Card.Title>
        </Link>

        <p className="my-auto" style={{ width: "15%" }}>
          <strong style={{ fontSize: "1rem", color: "#313131" }}>
            &#8377;{product.price}
          </strong>
        </p>
      </Row>

      <Row className="mb-1 px-1 py-0  d-flex justify-content-between align-items-center">
        <Col xs={7}>
          {/* <Link
            to={product.isNPI ? "/newproducts" : `/seller/${product.seller.id}`}
            className="text-decoration-none seller-name-listing"
          > */}
          <Card.Subtitle
            className="text-body-light"
            style={{
              fontSize: "0.9rem",
              margin: 0,
            }}
          >
            {/* {product.isNPI
                ? "New Product"
                : sliceToSize(product.seller.storeName, LISTING_SELLER_CHAR)} */}
          </Card.Subtitle>
          {/* </Link> */}
        </Col>
        <Col xs={5}>
          <AddButton
            cartQty={cartQty}
            product={product}
            changeQty={changeQty}
            removeFromCart={removeItemFromCart}
            trackingData={{
              eventHeading: "Added Product",
              eventProps: { method: "MAIN", screen: screen },
            }}
          />
        </Col>
      </Row>
      {showPreorderTag && <PreorderTagBadge />}
      {product?.id === showRecContainer && (
        <RecContainer
          rec={recItems}
          heading="Frequently bought together"
          styles={{ background: "#f5f5f5" }}
          screen={screen}
        />
      )}
    </Card>
  );
}
export default Product;
