import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";

const usePageTracking = (title, options = {}) => {
  const location = useLocation();

  useEffect(() => {
    amplitude.track("Page Viewed", {
      path: location.pathname,
      title: title,
      ...options,
    });
  }, [location.pathname]);
};

export default usePageTracking;
